export const STAGE_1_FORM = 'STAGE_1_FORM'
export const STAGE2_1FORM = 'STAGE2_1FORM'
export const STAGE2_2FORM = 'STAGE2_2FORM'
export const STAGE3_1FORM = 'STAGE3_1FORM'
export const STAGE3_4FORM = 'STAGE3_4FORM'
export const STAGE3_7FORM = 'STAGE3_7FORM'
export const STAGE_SIGN_FORM = 'STAGE_SIGN_FORM'

export const CONTRACT_SECTION_FORM = 'CONTRACT_SECTION_FORM'
export const FORM_SENT = 'FORM_SENT'
export const PARTY_B_RETURN = 'PARTY_B_RETURN'

export const FORM_ITEM_FOCUS = 'FORM_ITEM_FOCUS'
export const FORM_ITEM_BLUR = 'FORM_ITEM_BLUR'

export const SET_RESIZED_TO_MOBILE = 'SET_RESIZED_TO_MOBILE'
export const SET_RESIZED_TO_DESKTOP = 'SET_RESIZED_TO_DESKTOP'

export const DATA_MISSING =  'DATA_MISSING'
export const DATA_NOT_MISSING = 'DATA_NOT_MISSING'
export const USER_CLICKED_NEXT_INPUTS_ON_PAGE = 'USER_CLICKED_NEXT_INPUTS_ON_PAGE'
export const RESET_USER_CLICKED_NEXT_INPUTS_ON_PAGE = 'RESET_USER_CLICKED_NEXT_INPUTS_ON_PAGE'
export const INPUTS_ON_PAGE = 'INPUTS_ON_PAGE'
export const NO_INPUTS_ON_PAGE = 'NO_INPUTS_ON_PAGE'
export const HAVE_SUBMITTED_ONCE_ON_PAGE = 'HAVE_SUBMITTED_ONCE_ON_PAGE'
export const RESET_HAVE_SUBMITTED_ONCE_ON_PAGE = 'RESET_HAVE_SUBMITTED_ONCE_ON_PAGE'
export const USER_CANNOT_GO_NEXT =  'USER_CANNOT_GO_NEXT'
export const USER_CAN_GO_NEXT = 'USER_CAN_GO_NEXT'
