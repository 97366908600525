import React, { useState, useEffect } from 'react';
import debounce from '../services/Debounce.js'
import { connect } from 'react-redux';
import { useSpring, animated } from "react-spring";

function BackgroundParallax(props) {
    const [scrollY, setScrollY] = useState(0);
    
    useEffect(() => {
        const handleScroll = event => {
            setScrollY(window.scrollY)
        }
        window.addEventListener("scroll", debounce(handleScroll));
        return () => window.removeEventListener("scroll", debounce(handleScroll));
    },[]);

    const [{ springscrollY }, springsetScrollY] = useSpring(() => ({
        springscrollY: 0
      }));
    const parallaxLevel1 = 7
    const parallaxLevel2 = 7
    springsetScrollY({ springscrollY: scrollY });
    const shapeYCoord1 = springscrollY.interpolate(o => `translate3d(0, ${-o / parallaxLevel1}px, 0)`);
    const shapeYCoord2 = springscrollY.interpolate(o => `translate3d(0, ${-o / parallaxLevel2}px, 0)`);


    return (
        <>
        <animated.div  style={{ transform: shapeYCoord1, position: 'absolute',  left: '-750px', top: '300px', backfaceVisibility: 'hidden' }}>
            <span className="dotted-red-background contract"></span>
        </animated.div>
        <animated.div  style={{ transform: shapeYCoord2, position: 'absolute', right: '0px', top: '1471px', backfaceVisibility: 'hidden'}}>
            <span className="dotted-light-blue-background one"></span>
        </animated.div>
        <animated.div  style={{ transform: shapeYCoord1, position:'absolute', left: '-550px', top: '1900px' , backfaceVisibility: 'hidden'}}>
            <span className="dotted-black-background one"></span>
        </animated.div>
        <animated.div  style={{ transform: shapeYCoord1, position:'absolute', right: '-550px', top: '3400px' , backfaceVisibility: 'hidden'}}>
            <span className="dotted-black-background two"></span>
        </animated.div>
        <animated.div style={{ transform: shapeYCoord2, position:'absolute', left: '-309px', top: '5700px', backfaceVisibility: 'hidden'}}>
            <span className="dotted-light-blue-background two"></span>
        </animated.div>
        </>
    )
}

function mapStateToProps(state) {
    return { stageData: state.stage };
}

export default connect(mapStateToProps)(BackgroundParallax)