import { SWITCH_TO_1 , SWITCH_TO_2_1 , SWITCH_TO_2_2 , SWITCH_TO_3_1 , SWITCH_TO_3_2 , SWITCH_TO_3_3 , SWITCH_TO_3_4 , SWITCH_TO_3_5 , SWITCH_TO_3_6 , SWITCH_TO_3_7 , SWITCH_TO_3_8 , SWITCH_TO_3_9 , SWITCH_TO_4, SWITCH_TO_5, ACTIVE_START, ACTIVE_FILL_INFO, ACTIVE_READ_CAREFULLY, ACTIVE_SIGNATURE, ACTIVE_AGREEMENT, SWITCH_TO_START, SWITCH_TO_CONTRACT, SWITCH_TO_AGREEMENT } from './StageTypes.js';


let initialState = {
    loading: false,
    stage: '1',
    nextButtonClass: 'play',
    backButtonHide: true,
    navOne: 'active',
    navTwo: 'hide-number',
    navThree: 'hide-text',
    navFour: 'hide-text',
    navFive: 'hide-text',
    popupActive: false,
    startActive: true,
    fillInfoActive: false,
    readCarefullyActive: false,
    contractActive: false,
    agreementActive: false
}

const stageReducer = (state = initialState, action) => {
    switch (action.type) {
        case SWITCH_TO_1:
            return {
                ...state,
                stage: '1',
                startActive: true,
                contractActive: false,
                fillInfoActive: false,
                agreementActive: false
            }

        case SWITCH_TO_2_1: 
            return {
                ...state,
                stage: '2.1',
                startActive: false,
                contractActive: true,
                fillInfoActive: true,
                readCarefullyActive: false,
                agreementActive: false
            }

        case SWITCH_TO_2_2: 
            return {
                ...state,
                stage: '2.2',
                fillInfoActive: true,
                readCarefullyActive: false
            }
        
        case SWITCH_TO_3_1: 
            return {
                ...state,
                stage: '3.1',
                fillInfoActive: false,
                readCarefullyActive: true,
            }

        case SWITCH_TO_3_2: 
            return {
                ...state,
                stage: '3.2',
            }

        case SWITCH_TO_3_3: 
            return {
                ...state,
                stage: '3.3',

            }

        case SWITCH_TO_3_4: 
            return {
                ...state,
                stage: '3.4'
            }

        case SWITCH_TO_3_5: 
            return {
                ...state,
                stage: '3.5'
            }

        case SWITCH_TO_3_6: 
            return {
                ...state,
                stage: '3.6'
            }

        case SWITCH_TO_3_7: 
            return {
                ...state,
                stage: '3.7'
            }
        
        case SWITCH_TO_3_8: 
            return {
                ...state,
                stage: '3.8'
            }
        
        case SWITCH_TO_3_9: 
            return {
                ...state,
                stage: '3.9',
                readCarefullyActive: true,
                signActive: false
            }
        
        case SWITCH_TO_4: 
            return {
                ...state,
                stage: '4',
                startActive: false,
                readCarefullyActive: false,
                contractActive: true,
                signActive: true,
                agreementActive: false
            }
        
        case SWITCH_TO_5: 
            return {
                ...state,
                stage: '5',
                popupActive: true,
                startActive: false,
                contractActive: false,
                agreementActive: true,
                signActive: false
            }

        // for desktop 
        case SWITCH_TO_START: 
            return {
                ...state,
                startActive: true,
                contractActive: false,
                agreementActive: false
            }

        case SWITCH_TO_CONTRACT: 
            return {
                ...state,
                startActive: false,
                contractActive: true,
                agreementActive: false
            }

        case SWITCH_TO_AGREEMENT: 
            return {
                ...state,
                popupActive: true,
                startActive: false,
                contractActive: false,
                agreementActive: true
            }
        
        // below set the nav bar status' and the buttons on mobile
        case ACTIVE_START: 
            return {
                ...state,
                navOne: 'active',
                navTwo: 'hide-number',
                navThree: 'hide-text',
                navFour: 'hide-text',
                navFive: 'hide-text',
                nextButtonClass: 'play',
                backButtonHide: true

            }

        case ACTIVE_FILL_INFO: 
            return {
                ...state,
                navOne: 'hide',
                navTwo: 'active',
                navThree: 'hide-number',
                navFour: 'hide-text',
                navFive: 'hide-text',
                nextButtonClass: 'check',
                backButtonHide: false

            }

        case ACTIVE_READ_CAREFULLY:
            return {
                ...state,
                navOne: 'hide',
                navTwo: 'hide',
                navThree: 'active',
                navFour: 'hide-number',
                navFive: 'hide-text',
                nextButtonClass: 'check'

            }
        
        case ACTIVE_SIGNATURE: 
            return {
                ...state,
                navOne: 'hide',
                navTwo: 'hide',
                navThree: 'hide',
                navFour: 'active',
                navFive: 'hide-number',
                nextButtonClass: 'paper-aeroplane'
            }

        case ACTIVE_AGREEMENT: 
            return {
                ...state,
                navOne: 'hide',
                navTwo: 'hide',
                navThree: 'hide',
                navFour: 'hide',
                navFive: 'active',
                nextButtonClass: 'flag',
                backButtonHide: true
            }

    default:
        return state
    }
}

export default stageReducer