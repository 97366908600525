import React, { useState } from 'react';

function AccordionContainer(props) {
    let [active, setActive] = useState(false)
    let swiper = props.swiper

    // toggle active class
    const handleClick = () => {
        if (active) {
            setActive(false)
            setTimeout(() => {
                swiper.updateAutoHeight()
            }, 400)
        }
        else {
            setActive(true)
            setTimeout(() => {
                swiper.updateAutoHeight()
            }, 400)
        }
    }

    return (

    <div className={"infos" + (active ? ' active' : '')}>
        <div onClick={handleClick} className={"title-bg" + (props.colour === "blue" ? " blue" : " pink") }>
            <h4>{props.title}<span></span></h4>
        </div>

        <div className="text">
            <p>{props.text}</p>
            {props.textTwo ? <p>{props.textTwo}</p> : null}
        </div>
    </div>
    )
}

export default AccordionContainer