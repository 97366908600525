import { SWITCH_TO_1 , SWITCH_TO_2_1 , SWITCH_TO_2_2 , SWITCH_TO_3_1 , SWITCH_TO_3_2 , SWITCH_TO_3_3 , SWITCH_TO_3_4 , SWITCH_TO_3_5 , SWITCH_TO_3_6 , SWITCH_TO_3_7 , SWITCH_TO_3_8 , SWITCH_TO_3_9 , SWITCH_TO_4, SWITCH_TO_5, ACTIVE_START, ACTIVE_FILL_INFO, ACTIVE_READ_CAREFULLY, ACTIVE_SIGNATURE, ACTIVE_AGREEMENT, SWITCH_TO_START, SWITCH_TO_CONTRACT, SWITCH_TO_AGREEMENT } from './StageTypes.js';

export const switchTo1 = () => {
    return {
        type: SWITCH_TO_1
    }
}

export const switchTo2_1 = () => {
    return {
        type: SWITCH_TO_2_1
    }
}

export const switchTo2_2 = () => {
    return {
        type: SWITCH_TO_2_2
    }
}

export const switchTo3_1 = () => {
    return {
        type: SWITCH_TO_3_1
    }
}

export const switchTo3_2 = () => {
    return {
        type: SWITCH_TO_3_2
    }
}

export const switchTo3_3 = () => {
    return {
        type: SWITCH_TO_3_3
    }
}

export const switchTo3_4 = () => {
    return {
        type: SWITCH_TO_3_4
    }
}

export const switchTo3_5 = () => {
    return {
        type: SWITCH_TO_3_5
    }
}

export const switchTo3_6 = () => {
    return {
        type: SWITCH_TO_3_6
    }
}

export const switchTo3_7 = () => {
    return {
        type: SWITCH_TO_3_7
    }
}

export const switchTo3_8 = () => {
    return {
        type: SWITCH_TO_3_8
    }
}

export const switchTo3_9 = () => {
    return {
        type: SWITCH_TO_3_9
    }
}

export const switchTo4 = () => {
    return {
        type: SWITCH_TO_4
    }
}

export const switchTo5 = () => {
    return {
        type: SWITCH_TO_5
    }
}

export const activeStart = () => {
    return {
        type: ACTIVE_START
    }
}

export const activeFillInfo = () => {
    return {
        type: ACTIVE_FILL_INFO
    }
}

export const activeReadCarefully = () => {
    return {
        type: ACTIVE_READ_CAREFULLY
    }
}

export const activeSignature = () => {
    return {
        type: ACTIVE_SIGNATURE
    }
}

export const activeAgreement = () => {
    return {
        type: ACTIVE_AGREEMENT
    }
}

export const switchToStart = () => {
    return {
        type: SWITCH_TO_START
    }
}

export const switchToContract = () => {
    return {
        type: SWITCH_TO_CONTRACT
    }
}

export const switchToAgreement = () => {
    return {
        type: SWITCH_TO_AGREEMENT
    }
}

// this is passed the new stage to go to and dispatches it by selecting an action (actions are above here)
export const stageSwitch = (newStage) => {
    
    return(dispatch) => {
        switch (newStage) {   
            case '1' :
                return dispatch(switchTo1()) 
                
            case '2.1' :
                return  dispatch(switchTo2_1())
            
            case '2.2' :
                return  dispatch(switchTo2_2())
            
            case '3.1' :
                return  dispatch(switchTo3_1())

            case '3.2' :
                return  dispatch(switchTo3_2())

            case '3.3' :
                return  dispatch(switchTo3_3())

            case '3.4' :
                return  dispatch(switchTo3_4())

            case '3.5' :
                return  dispatch(switchTo3_5())
            
            case '3.6' :
                return  dispatch(switchTo3_6())

            case '3.7' :
                return  dispatch(switchTo3_7())

            case '3.8' :
                return  dispatch(switchTo3_8())
            
            case '3.9' :
                return  dispatch(switchTo3_9())

            case '4' :
                return  dispatch(switchTo4())
            
            case '5' :
                return  dispatch(switchTo5())

            default:
                return newStage
        }
    }
}  