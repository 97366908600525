import React, { useEffect, useState } from 'react'
import { throttle } from 'throttle-debounce';
import { connect } from 'react-redux';

import { activeStart, activeFillInfo, activeReadCarefully, activeSignature, activeAgreement } from '../redux';

const Header = props => {
    let contractActive = props.stageData.contractActive
    let [greyArrowActive, setGreyArrowActive] = useState(false)
    let [greyArrowShow, setGreyArrowShow] = useState(false)
    let [positionCurrent, setPositionCurrent] = useState('')
    
    let stage = props.stageData.stage 

    const handleScroll = throttle(600, false, event => {  
        // fill info
        const fillInfo = props.readCarefulStart.current.getBoundingClientRect().top > 150
        const readCarefully = props.readCarefulStart.current.getBoundingClientRect().top <= 150 && props.readCarefulStart.current.getBoundingClientRect().top >= -3464 && props.agreeSect.current.getBoundingClientRect().top === 0
        const signature = props.readCarefulStart.current.getBoundingClientRect().top < -3464

        if (fillInfo) {
            props.activeFillInfo()
        }
        // read carefully
        else if (readCarefully) {
            props.activeReadCarefully()
        } 
        // signature 
        else if (signature) {
            props.activeSignature() 
        }
    })

    // on scroll change active classes in navbar for desktop
    useEffect(() => {
        // if user is on the contract section (only applies to desktop)
        if (contractActive) {
            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contractActive, props.readCarefulStart, props.agreeSect])

    

    useEffect(() => {
        if(stage !== '1')
            setGreyArrowShow(true)
        else
            setGreyArrowShow(false)
    }, [stage])

    useEffect(() => {
        setGreyArrowActive(false)
    }, [stage])

    useEffect(() => {
        if(props.stageData.startActive && greyArrowActive)
            setPositionCurrent(' start')
        else if(props.stageData.fillInfoActive && greyArrowActive)
            setPositionCurrent(' fill-info')
        else if(props.stageData.readCarefullyActive && greyArrowActive)
            setPositionCurrent(' read-carefully')
        else if(props.stageData.signActive && greyArrowActive)
            setPositionCurrent(' sign')
        else if(props.stageData.agreementActive && greyArrowActive)
            setPositionCurrent(' agreement')
        else
            setPositionCurrent('')
    }, [greyArrowActive, positionCurrent, props.stageData.startActive, props.stageData.fillInfoActive, props.stageData.readCarefullyActive, props.stageData.signActive, props.stageData.agreementActive])

    // rotate grey arrow on the left of the header on mobile
    const greyArrowClicked = event => {
        if(greyArrowActive)
            setGreyArrowActive(false)
        else if (!greyArrowActive && stage !== '5')
            setGreyArrowActive(true)
    }

    // this is sent to SwiperNavContainer to take the user to the slide they clicked on in prev-nav-container
    const goToPrev = event => {
        const id = event.currentTarget.id
        props.slideTo(id)

        setTimeout(()=> {props.slideTo('')}, 500)
    }

    // desktop version header clicked - scroll down to that section on the page
    const navClicked = event => {
        const id = event.currentTarget.id

        const scrollTo = (section, yOffset) => {
            window.scrollTo({top: props[section].current.getBoundingClientRect().top + window.pageYOffset  + yOffset, behavior: 'smooth'});  
        }

        if(id === 'fill-info-main-header') {
            scrollTo('fillInfoStart', -60) 
        } else if (id === 'read-carefully-main-header') {
            scrollTo('readCarefulStart', -80) 
        } else if (id === 'sign-main-header') {
            scrollTo('signatureStart', -80) 
        }
    }

    return(
        <header>
            <nav>
                <ul>
                    {/* prev-nav-container is shown when user clicks grey arrow on far left on desktop */}
                    <div className={"prev-nav-container" + positionCurrent + (greyArrowActive ? ' show' : '')}>
                        {props.stageData.navOne ===  'hide' ? <li id='start-prev-header' onClick={goToPrev} className={'start-header show-prev' + (greyArrowActive ? ' show' : '' )}><span className="num-circle">1</span></li> : null }
                        {props.stageData.navTwo ===  'hide' ? <li id='fill-info-prev-header' onClick={goToPrev} className={'fill-info show-prev' + (props.stageData.navTwo === 'hide' && greyArrowActive ? ' show' : '' )}><span className="num-circle">2</span></li> : null}
                        {props.stageData.navThree === 'hide' ? <li id='read-carefully-prev-header' onClick={goToPrev} className={'read-carefully show-prev' + (props.stageData.navThree === 'hide' && greyArrowActive ? ' show' : '' )}><span className="num-circle">3</span></li> : null}
                        {props.stageData.navFour === 'hide' ? <li id='sign-prev-header' onClick={goToPrev} className={'sign show-prev' + (props.stageData.navFour === 'hide' && greyArrowActive ? ' show' : '' )}><span className="num-circle">4</span></li> : null}
                    </div>
                    {/* current nav container is header area for what is ahead or current on mobile, or everything on desktop */}
                    <div className={"current-nav-container" + positionCurrent}>
                        { greyArrowShow ? <li onClick={greyArrowClicked} className={'grey-arrow' + (greyArrowActive ? ' rotate' : '')}></li> : null}
                        <li className={'start-header ' + props.stageData.navOne}><span className="num-circle">1</span><span className="text">Start</span></li>        
                        <li id='fill-info-main-header' className={'fill-info ' + props.stageData.navTwo + (props.stageData.contractActive ? ' clickable' : '')} onClick={navClicked}><span className="num-circle">2</span><span className="text">Fill in information</span></li>                    
                        <li id='read-carefully-main-header' className={'read-carefully ' + props.stageData.navThree + (props.stageData.contractActive ? ' clickable' : '')} onClick={navClicked}><span className="num-circle">3</span><span className="text">Read Carefully</span></li>                   
                        <li id='sign-main-header' className={'sign ' + props.stageData.navFour + (props.stageData.contractActive ? ' clickable' : '')} onClick={navClicked}><span className="num-circle">4</span><span className="text">Sign</span></li>
                        <li className={'agreement ' + props.stageData.navFive}><span className="num-circle">5</span><span className="text">New agreement</span></li>     
                    </div>
                </ul>
            </nav>
        </header>
    )
}

function mapStateToProps(state) {
    return { 
        stageData: state.stage,
        formData: state.form 
    };
  }

const mapDispatchToProps = dispatch => {
    return {
        activeStart: () => dispatch(activeStart()),
        activeFillInfo: () => dispatch(activeFillInfo()),
        activeReadCarefully: () => dispatch(activeReadCarefully()),
        activeSignature: () => dispatch(activeSignature()),
        activeAgreement: () => dispatch(activeAgreement())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)