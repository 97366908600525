import { STAGE_1_FORM, STAGE2_1FORM, STAGE2_2FORM, STAGE3_1FORM, STAGE3_4FORM, STAGE3_7FORM, STAGE_SIGN_FORM, CONTRACT_SECTION_FORM, FORM_SENT, PARTY_B_RETURN, FORM_ITEM_FOCUS, FORM_ITEM_BLUR, SET_RESIZED_TO_DESKTOP, SET_RESIZED_TO_MOBILE, DATA_MISSING, DATA_NOT_MISSING, USER_CLICKED_NEXT_INPUTS_ON_PAGE, RESET_USER_CLICKED_NEXT_INPUTS_ON_PAGE, INPUTS_ON_PAGE, NO_INPUTS_ON_PAGE, HAVE_SUBMITTED_ONCE_ON_PAGE, RESET_HAVE_SUBMITTED_ONCE_ON_PAGE, USER_CAN_GO_NEXT, USER_CANNOT_GO_NEXT} from './FormTypes.js';

let initialState = {
    stage: '1',
    nextDisabled: false,
    keysExpected: 0,
    formSent: false,
    partyBReturn: false,
    formItem: 'blur',
    resizedTo: '',
    dataMissing: false,
    userClickedNextInputsOnPage: false,
    inputsOnPage: false,
    haveSubmittedOnceOnPage: false,
    userCanGoNext: true
}

const formReducer = (state = initialState, action) => {
    switch(action.type) {
        // for mobile
        case STAGE_1_FORM:
            return {
                ...state,
                stage: '1',
                keysExpected: 0
            }

        case STAGE2_1FORM:
            return {
                ...state,
                stage: '2.1',
                keysExpected: 12
            }

        case STAGE2_2FORM:
            return {
                ...state,
                stage: '2.2',
                keysExpected: 17
            }

        case STAGE3_1FORM:
            return {
                ...state,
                stage: '3.1',
                keysExpected: 18
            }

        case STAGE3_4FORM:
            return {
                ...state,
                stage: '3.4',
                keysExpected: 22
            }

        case STAGE3_7FORM:
            return {
                ...state,
                stage: '3.7',
                keysExpected: 25
            }
        
        case STAGE_SIGN_FORM:
            return {
                ...state,
                stage: '4',
                keysExpected: 27
            }

        case PARTY_B_RETURN:
            return {
                ...state,
                partyBReturn: true
            }

        case FORM_ITEM_FOCUS:
            return {
                ...state,
                formItem: 'focus'
            }
        
        case FORM_ITEM_BLUR:
            return {
                ...state,
                formItem: 'blur'
            }
        
        case DATA_MISSING: 
            return {
                ...state,
                dataMissing: true
            }
        
        case DATA_NOT_MISSING:
            return {
                ...state,
                dataMissing: false
            }

        case USER_CLICKED_NEXT_INPUTS_ON_PAGE: 
            return {
                ...state,
                userClickedNextInputsOnPage: true
            }

        case RESET_USER_CLICKED_NEXT_INPUTS_ON_PAGE:
            return {
                ...state,
                userClickedNextInputsOnPage: false
            }
        
        case INPUTS_ON_PAGE:
            return {
                ...state,
                inputsOnPage: true
            }
        
        case NO_INPUTS_ON_PAGE:
            return {
                ...state,
                inputsOnPage: false
            }
        
        case HAVE_SUBMITTED_ONCE_ON_PAGE:
            return {
                ...state,
                haveSubmittedOnceOnPage: true
            }

        case RESET_HAVE_SUBMITTED_ONCE_ON_PAGE:
            return {
                ...state,
                haveSubmittedOnceOnPage: false
            }
        
        case USER_CAN_GO_NEXT: 
            return {
                ...state,
                userCanGoNext: true
            }
        
        case USER_CANNOT_GO_NEXT: 
            return {
                ...state,
                userCanGoNext: false
            }

        // for resizing
        case SET_RESIZED_TO_MOBILE:
            return {
                ...state,
                resizedTo: 'mobile'
            }

        case SET_RESIZED_TO_DESKTOP:
            return {
                ...state,
                resizedTo: 'desktop'
            }

        // for desktop
        case CONTRACT_SECTION_FORM:
            return {
                ...state,
                keysExpected: 27,
                nextDisabled: true
            }
        
        case FORM_SENT:
            return {
                ...state,
                formSent: true
            }
        default:
            return state
    }
}

export default formReducer