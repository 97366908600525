// different sections and parts to switch to
    // for mobile
export const SWITCH_TO_1 = 'SWITCH_TO_1'
export const SWITCH_TO_2_1 = 'SWITCH_TO_2_1'
export const SWITCH_TO_2_2 = 'SWITCH_TO_2_2'
export const SWITCH_TO_3_1 = 'SWITCH_TO_3_1'
export const SWITCH_TO_3_2 = 'SWITCH_TO_3_2'
export const SWITCH_TO_3_3 = 'SWITCH_TO_3_3'
export const SWITCH_TO_3_4 = 'SWITCH_TO_3_4'
export const SWITCH_TO_3_5 = 'SWITCH_TO_3_5'
export const SWITCH_TO_3_6 = 'SWITCH_TO_3_6'
export const SWITCH_TO_3_7 = 'SWITCH_TO_3_7'
export const SWITCH_TO_3_8 = 'SWITCH_TO_3_8'
export const SWITCH_TO_3_9 = 'SWITCH_TO_3_9'
export const SWITCH_TO_4 = 'SWITCH_TO_4'
export const SWITCH_TO_5 = 'SWITCH_TO_5'

    // for desktop
export const SWITCH_TO_START = 'SWITCH_TO_START'
export const SWITCH_TO_CONTRACT = 'SWITCH_TO_CONTRACT'
export const SWITCH_TO_AGREEMENT = 'SWITCH_TO_AGREEMENT'

    // for both 
export const ACTIVE_START = 'ACTIVE_START'
export const ACTIVE_FILL_INFO = 'ACTIVE_FILL_INFO'
export const ACTIVE_READ_CAREFULLY = 'ACTIVE_READ_CAREFULLY'
export const ACTIVE_SIGNATURE = 'ACTIVE_SIGNATURE'
export const ACTIVE_AGREEMENT = 'ACTIVE_AGREEMENT'
