import { STAGE_1_FORM, STAGE2_1FORM, STAGE2_2FORM, STAGE3_1FORM, STAGE3_4FORM, STAGE3_7FORM, STAGE_SIGN_FORM, CONTRACT_SECTION_FORM, FORM_SENT, PARTY_B_RETURN, FORM_ITEM_FOCUS, FORM_ITEM_BLUR, SET_RESIZED_TO_DESKTOP, SET_RESIZED_TO_MOBILE, DATA_MISSING, DATA_NOT_MISSING, USER_CLICKED_NEXT_INPUTS_ON_PAGE, INPUTS_ON_PAGE, NO_INPUTS_ON_PAGE, RESET_USER_CLICKED_NEXT_INPUTS_ON_PAGE, HAVE_SUBMITTED_ONCE_ON_PAGE, RESET_HAVE_SUBMITTED_ONCE_ON_PAGE, USER_CAN_GO_NEXT, USER_CANNOT_GO_NEXT } from './FormTypes.js'

export const userCanGoNext = () => {
    return {
        type: USER_CAN_GO_NEXT
    }
}

export const userCannotGoNext = () => {
    return {
        type: USER_CANNOT_GO_NEXT
    }
}

export const dataMissing = () => {
    return {
        type: DATA_MISSING
    }
}

export const dataNotMissing = () => {
    return {
        type: DATA_NOT_MISSING
    }
}

export const inputsOnPage = () => {
    return {
        type: INPUTS_ON_PAGE
    }
}

export const noInputsOnPage = () => {
    return {
        type: NO_INPUTS_ON_PAGE
    }
}

export const setResizedToMobile = () => {
    return {
        type: SET_RESIZED_TO_MOBILE
    }
}

export const setResizedToDesktop = () => {
    return {
        type: SET_RESIZED_TO_DESKTOP
    }
}

export const formItemFocus = () => {
    return {
        type: FORM_ITEM_FOCUS
    }
}

export const formItemBlur = () => {
    return {
        type: FORM_ITEM_BLUR
    }
}

export const partyBReturn = () => {
    return {
        type: PARTY_B_RETURN
    }
}

export const stage_1_Form = () => {
    return {
        type: STAGE_1_FORM
    }
}
export const stage2_1Form = () => {
    return {
        type: STAGE2_1FORM
    }
}

export const stage2_2Form = () => {
    return {
        type: STAGE2_2FORM
    }
}

export const stage3_1Form  = () => {
    return {
        type: STAGE3_1FORM
    }
}

export const stage3_4Form  = () => {
    return {
        type: STAGE3_4FORM
    }
}

export const stage3_7Form  = () => {
    return {
        type: STAGE3_7FORM
    }
}

export const stageSignForm = () => {
    return {
        type: STAGE_SIGN_FORM
    }
}

export const contractSectionForm = () => {
    return {
        type: CONTRACT_SECTION_FORM
    }
}

export const formSent = () => {
    return {
        type: FORM_SENT
    }
}

export const userClickedNextInputsOnPage = () => {
    return {
        type: USER_CLICKED_NEXT_INPUTS_ON_PAGE
    }
}

export const resetUserClickedNextInputsOnPage = () => {
    return {
        type: RESET_USER_CLICKED_NEXT_INPUTS_ON_PAGE
    }
}

export const haveSubmittedOnceOnPage = () => {
    return {
        type: HAVE_SUBMITTED_ONCE_ON_PAGE
    }
}

export const resetHaveSubmittedOnceOnPage = () => {
    return {
        type: RESET_HAVE_SUBMITTED_ONCE_ON_PAGE
    }
}

// this is passed the new stage to go to and dispatches it by selecting an action (actions are above here)
export const formSwitch = (newStage) => {
    
    return(dispatch) => {

        switch (newStage) {  
            
            case '1' :
                return  dispatch(stage_1_Form())
                
            case '2.1' :
                return  dispatch(stage2_1Form())
            
            case '2.2' :
                return  dispatch(stage2_2Form())
            
            case '3.1' :
                return  dispatch(stage3_1Form())

            case '3.4' :
                return  dispatch(stage3_4Form())

            case '3.7' :
                return  dispatch(stage3_7Form())

            case '4' :
                return  dispatch(stageSignForm())

            default:
                return newStage
        }
    }
}  