const scrollToTop = () => {
    // go to top of page
    return window.scrollTo(0, 0)
}

let config = {
    apiCreateContract: '/create-contract',
    apiGetContract: '/get-contract',
    apiUpdateContract: '/update-contract',
    apiGetJurisdiction: '/jurisdictions/all',
    apiGetCompanies: '/companies/all'
}

export { config, scrollToTop }