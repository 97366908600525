import { useState, useEffect } from 'react';
import axios from 'axios';
import { config } from '../services';

import { isMobile } from "react-device-detect";

const UseFormInput = callback => {
  let [values, setValues] = useState({})
  let [tempDate, setTempDate] = useState('')
  let [emailErr, setEmailErr] = useState({
    emailA: true,
    emailB: true
  })
  let [emailErrSign, setEmailErrSign] = useState({
    emailASign: true,
    emailBSign: true
  })
  let [loading, setLoading] = useState(false)
  let [modalShowing, setModalShowing] = useState(false)
  let [password, setPassword] = useState('')
  let [passwordError, setPasswordError] =  useState(false)
  let [partyBReturn, setPartyBReturn] = useState(false)
  const [companies, setCompanies] = useState([])
  const [jurisdictions, setJurisdictions] = useState([])
  const [repNameAOptions, setRepNameAOptions] = useState([])
  const [repNameCOptions, setRepNameCOptions] = useState([])
  let path = window.location.pathname

  // on initial loading
  useEffect(() => {
        axios.get(process.env.REACT_APP_API_PATH + config.apiGetCompanies).then(companiesData => {
            setCompanies(companiesData.data)
            setJurisdictions(companiesData.data[0].jurisdictions)
            setRepNameAOptions(companiesData.data[0].signatories)
            const repNameCOptionsFiltered = companiesData.data[0].signatories.filter(r => r.name !== companiesData.data[0].signatories[0].name)
            setRepNameCOptions(repNameCOptionsFiltered)
        })
  }, [])

// FIRST SECTION IS FOR THE FORM, SECOND IS FOR PASSWORD (password is used for when the user returns)

// FORM
// if startDate or tempDate are an object and duration is filled (i.e, if they are a date object) call display end date above
  const displayEndDate = date => {
    const year = date.getFullYear()
    const month = date.getMonth()
    const day = date.getDate()
    setValues( values => ({...values, endDate: new Date(year  + 3, month, day) } ))
  }

  // fetch stored form and display on form
  useEffect(() => {  
    if(path !== "/") {
      setModalShowing(true)
    }
  }, [path])

  // calculate date
  useEffect(() => {
    // desktop
    if(typeof(values.startDate) === "object") {
      displayEndDate(values.startDate)
    }
     // mobile
    if (typeof(tempDate) === "object") {
      displayEndDate(tempDate)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.startDate, values.duration, tempDate])

  // check if email has an error
  useEffect(() => {
    const emailCheck = (key, sign) => {
      let emailMatch = values[key].toLowerCase().match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)
  
      if (emailMatch === null) {
        if(sign) {
          setEmailErrSign( values => ({...values, [key]: true}))
        } else {
          setEmailErr( values => ({...values, [key]: true }) )
        }
      }
      else if (emailMatch || emailMatch === '') {
        if(sign) {
          setEmailErrSign( values => ({...values, [key]: false}))
        } else {
          setEmailErr( values => ({...values, [key]: false }) )
        }
      }
    }

    if (values.emailA) {
      emailCheck('emailA', false)
    }

    if (values.emailB) {
      emailCheck('emailB', false)
    }

    if (values.emailASign) {
      emailCheck('emailASign', true)
    }

    if (values.emailBSign) {
      emailCheck('emailBSign', true)
    }

    if (values.emailCSign) {
      emailCheck('emailCSign', true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.emailA, values.emailB, values.emailASign, values.emailBSign, values.emailCSign, emailErr.emailA, emailErr.emailB, emailErrSign.emailASign, emailErrSign.emailBSign])


  // puts start date into date object so that the end date can be calculated 
  const handleStartDateMobile = event => {
    setTempDate(new Date (event.target.value))
  }

  // for the date picker (since the event is already a date object)
  const handleStartDateDesk = event => {
    setValues( values => ({ ...values, startDate: event }) )
  }
  
  // as the user is typing/makes a change in the form
  const handleFormChange = event => {
    event.persist()

    if (event.target.type === 'date') {
      handleStartDateMobile(event)
    }

    let name = event.target.name
    if(name === 'compNameA') {
      const index = companies.findIndex(c => c.name === event.target.value)
      const type = companies[index].type
      const nationality = companies[index].nationality
      const address = companies[index].address

      setJurisdictions(companies[index].jurisdictions)
      setValues(values => ({
        ...values,
        courts: companies[index].jurisdictions[0].court
      }))
      setRepNameAOptions(companies[index].signatories)
      const repNameCOptionsFiltered = companies[index].signatories.filter(r => r.name !== companies[index].signatories[0].name)
      setRepNameCOptions(repNameCOptionsFiltered)

      return setValues(values =>  ({
        ...values,
        compNameA: event.target.value,
        compTypeA: type,
        nationalityA: nationality,
        addressA: address,
        repNameA: 'placeholder',
        contactNameASign: null,
        positionA: null,
        positionASign: null,
        emailASign: null,
        contactNameCSign: 'placeholder',
        positionCSign: null,
        emailCSign: null
      }))
    }

    if(name === 'repNameA' || name === 'contactNameCSign') {
      const compIndex = companies.findIndex(c => c.name === values.compNameA)
      const signatories = companies[compIndex].signatories
      const signatory = signatories.find(s => s.name === event.target.value)
      
      if(name === 'repNameA') {
        const repNameCOptionsFiltered = signatories.filter(s => s.name !== event.target.value)
        setRepNameCOptions(repNameCOptionsFiltered)
        return setValues(values => ({
          ...values,
          repNameA: event.target.value,
          contactNameASign: event.target.value,
          positionA: signatory.role,
          positionASign: signatory.role,
          emailASign: signatory.email,
          contactNameCSign: 'placeholder',
          positionCSign: null,
          emailCSign: null
        }))
      } else {
        const repNameAOptionsFiltered = signatories.filter(s => s.name !== event.target.value)
        setRepNameAOptions(repNameAOptionsFiltered)

        return setValues(values => ({
          ...values,
          contactNameCSign: event.target.value,
          positionCSign: signatory.role,
          emailCSign: signatory.email
        }))
      }
    }

    if(name === 'legalRegulations') {
      const court = jurisdictions.find(c => c.law === event.target.value)
      return setValues(values => ({
        ...values,
        legalRegulations: event.target.value,
        courts: court.court
      }))
    }
    // do not allow party B to change what party A wrote 
    // Tom 25 June - I believe you can remove this since I've disabled the inputs but not enough time to test
    if (partyBReturn) {
      if (name === 'compNameA' || name === 'compTypeA' || name === 'nationalityA' || name === 'addressA' || name === 'repNameA' || name === 'positionA' || name === 'contactNameA' || name === 'emailA' || name === 'mobileA' || name === 'contactNameASign' || name ==='emailASign') 
        return 
    }

    setValues( values => ({ ...values, [event.target.name]: event.target.value }) );
  };

  // PASSWORD
  const handlePasswordChange = event => {
    setPassword(event.target.value)
    if(passwordError) 
      setPasswordError(false)
  }

  // when party B submits their password
  const checkPassword = () => {
    setLoading(true)

    axios.get(process.env.REACT_APP_API_PATH + config.apiGetContract + path + '/' + password).then((response) => { 
      // if user providers correct password, update their information
      let data = response.data

      let endDate = ''
      if (data.endDate !== null)
          endDate = new Date (data.endDate)

      let startDate = ''
      if (data.startingDate && isMobile)
          startDate = data.startingDate.split('T')[0]
      else if (data.startingDate && !isMobile)
          startDate = new Date(data.startingDate)

      let partyAaddress = ''
      let partyBaddress = ''

      if(data.partyAinfo.address)
        partyAaddress = data.partyAinfo.address.includes('↵') ? data.partyAinfo.address.replace(/↵/g, '\n') : data.partyAinfo.address

      if(data.partyBinfo.address)
        partyBaddress = data.partyBinfo.address.includes('↵') ? data.partyBinfo.address.replace(/↵/g, '\n') : data.partyBinfo.address

      setValues({
        addressA: partyAaddress,
        addressB: partyBaddress,
        compNameA: data.partyAinfo.company,
        compNameB: data.partyBinfo.company,
        compTypeA: data.partyAinfo.companyType,
        compTypeB: data.partyBinfo.companyType,
        confidentialInfoTypes: data.informationIncluded,
        contactNameA: data.partyAcontact.name,
        contactNameB: data.partyBcontact.name,
        contactNameASign: data.partyAsignature.name,
        contactNameBSign: data.partyBsignature.name,
        contactNameCSign: data.partyCsignature.name,
        courts: data.courtJurisdiction,
        emailA: data.partyAcontact.email,
        emailB: data.partyBcontact.email,
        emailASign: data.partyAsignature.email,
        emailBSign: data.partyBsignature.email,
        emailCSign: data.partyCsignature.email,
        endDate: endDate,
        legalRegulations: data.applicableLaw,
        nationalityA: data.partyAinfo.country,
        nationalityB: data.partyBinfo.country,
        noticeExpDate: data.daysPriorNotice,
        numDays: data.daysAfterDeclarationDispute,
        positionA: data.partyAinfo.position,
        positionASign: data.partyAsignature.position,
        positionB: data.partyBinfo.position,
        positionBSign: data.partyBsignature.position,
        positionCSign: data.partyCsignature.position,
        projectName: data.project,
        repNameA: data.partyAinfo.name,
        repNameB: data.partyBinfo.name,
        startDate: startDate
      })
      setModalShowing(false)
      setPartyBReturn(true)
      setLoading(false)
      // if their password is incorrect, don't allow them to go to next step
    }).catch(error => {
        setLoading(false)
        setPasswordError(true)
    })
  }

  // pass all this infomrmation back to App.js
  return {
    handleFormChange,
    handleStartDateDesk,
    values,
    emailErr,
    emailErrSign,
    loading, 
    setLoading,
    modalShowing,
    handlePasswordChange, 
    password,
    checkPassword,
    passwordError,
    partyBReturn,
    jurisdictions,
    companies,
    repNameAOptions,
    repNameCOptions
  }
};

export default UseFormInput