import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { formItemFocus, formItemBlur } from '../redux';

function InputInfoContainer(props) {
    let [active, setActive] = useState(false)
    const sharedProps = {
        onFocus: () => props.formItemFocus(),
        onBlur: ()  => props.formItemBlur(),
        onChange: props.handleFormChange,
        value: props.value,
        name: props.name, 
        className: props.className, 
        placeholder: props.placeholder,
        disabled: props.disabled  
    }
    const iconClicked = () => {
        if (active)
            setActive(false)
        else 
            setActive(true)
    }

    useEffect(() => {
        if(props.placeholder.includes('&#10;'))
            props.placeholder.replace(/&#10;/g, /&#10;/)
    }, [props.placeholder])

    return (
    <div className={props.containerClass + (active ? ' active' : '')}>
        <div className={"icon-wrapper"  + (props.error ? ' error' : '') + (props.input === 'textarea' ? ' textarea' : '') + (props.input === 'select' ? ' select' : '')}>
            { 
                props.input === 'input' && 
                <input {...sharedProps} />
            }
            { 
                props.input === 'textarea' && 
                <textarea  {...sharedProps} maxLength={props.maxLength} disabled={props.disabled} />
            }
            { 
                props.input === 'number' && 
                <input {...sharedProps} type="number"/> 
            }
            { 
                props.input === 'select' && 
                <select {...sharedProps} defaultValue="placeholder">
                    <option disabled value="placeholder">{props.placeholder}</option>
                    {
                        props.options.length > 0 && props.options.map(option => (
                            <option value={option.name}>{option.name}</option>
                        ))
                    }
                </select> 
                }
            {/* show error icon if there's an error. If hideInfo isn't a prop, then show the small icon which when clicked will bring up the tooltip */}
            { 
                props.error ? 
                    <img className={props.input === 'textarea' ? 'textarea-icon' : ''} onClick={iconClicked} src="/info-icon-error.png" alt="error" /> 
                : 
                !props.hideInfo && 
                    <img className={props.input === 'textarea' ? 'textarea-icon' : ''} onClick={iconClicked} src="/info-icon-grey.png" alt="info" disabled={props.disabled}/> 
            }
        </div>

        { 
            props.labelText && props.labelFontWeight !== 'bold' ? 
            <label htmlFor={props.labelHtml}>{props.labelText}</label> 
            : 
            <label htmlFor={props.labelHtml}><strong>{props.labelText}</strong></label> 
        }

        {
            !props.hideInfo &&
            <div className="helper-text-container">
                <span className="close" onClick={() => {setActive(false)}}></span>
                <p className="helper-text">{props.text}</p>
            </div>
        }
    </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        formItemFocus: () => dispatch(formItemFocus()),
        formItemBlur: () => dispatch(formItemBlur())
    }
}

export default connect(null, mapDispatchToProps)(InputInfoContainer)