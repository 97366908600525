import React, { useRef, useEffect, useState } from "react";
import Header from "./components/Header.js";
import SwiperNavContainer from "./components/SwiperNavContainer.js";
import SpinningLoader from "./components/SpinningLoader.js";
import AccordionContainer from "./components/AccordionContainer.js";
import BlueUnderlineContainer from "./components/BlueUnderlineContainer.js";
import UseFormInput from "./components/UseFormInput.js";
import ModalContainer from "./components/ModalContainer.js";
import InputInfoContainer from "./components/InputInfoContainer.js";
// import Copyright from './components/Copyright.js';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import axios from "axios";
import BackgroundParallax from "./components/BackgroundParallax.js";
import {
  isMobile,
  isMobileOnly,
  isMobileSafari,
  isIE,
} from "react-device-detect";
import { connect } from "react-redux";
import { scrollToTop, config } from "./services";
import {
  switchToStart,
  switchToContract,
  switchToAgreement,
  activeStart,
  activeFillInfo,
  activeAgreement,
  contractSectionForm,
  formSent,
  partyBReturn,
  formItemFocus,
  formItemBlur,
  dataMissing,
  dataNotMissing,
  inputsOnPage,
  userClickedNextInputsOnPage,
  userCanGoNext,
  userCannotGoNext,
  noInputsOnPage,
  setResizedToDesktop,
  setResizedToMobile,
} from "./redux";

SwiperCore.use([Navigation]);

function App(props) {
  // refs for sections
  let fillInfoStart = useRef();
  let readCarefulStart = useRef();
  let signatureStart = useRef();
  let agreeSect = useRef();
  let mainSect = useRef();
  let parallaxRef = useRef();

  let [swiper, setSwiper] = useState("");
  let [slideToId, setSlideToId] = useState("");
  let [popupActive, setPopupActive] = useState(false);
  let path = window.location.pathname;
  let screenHeight = window.innerHeight;
  // next button was just clicked and there are fields on the current page (particularly relevant for mobile)
  let nextJustClicked = props.formData.userClickedNextInputsOnPage;
  const [goToAgree, setGoToAgree] = useState({ goTo: false, event: null });
  // everything referred to as values, handleChange in the inputs is passed to useForm
  const input = () => {
    console.log(values);
  };
  const {
    values,
    handleFormChange,
    handleStartDateDesk,
    companies,
    jurisdictions,
    repNameAOptions,
    repNameCOptions,
    emailErr,
    emailErrSign,
    loading,
    setLoading,
    modalShowing,
    handlePasswordChange,
    password,
    checkPassword,
    passwordError,
    partyBReturn,
  } = UseFormInput(input);
  let emailError = Object.values(emailErr).findIndex((el) => el === true) > -1;
  let emailErrorSignature =
    Object.values(emailErrSign).findIndex((el) => el === true) > -1;
  // def is short for definite
  // next clicked desktop is also added because there is an issue when using reduxStage === 'x' || defDesktop in className. Error is that useEffect cannot be used within a condition. This happens after adding this condition to about 6 classes
  let defDesktop = () => {
    return !isMobileOnly || props.formData.resizedTo === "desktop";
  };
  let defMobile = isMobileOnly || props.formData.resizedTo === "mobile";
  let nextClickDtop = defDesktop && nextJustClicked;
  let reduxStage = props.stageData.stage;

  useEffect(() => {
    if (inputsOnPage()) {
      props.inputsOnPage();
    } else if (!inputsOnPage()) {
      props.noInputsOnPage();
      props.userCanGoNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.stageData.stage]);

  // BOTH MOBILE && DESKTOP
  // different parts of the expectedValuesObject are passed to isDataMissing()
  // isDataMissing() obviously checks whether there is anything missing in order for the user to move to the next step
  // MOBILE
  // whether party B is returning or party A is starting, reduxStage is checked
  // on some sections (such as stage 3.4 and 4), if there is an error in an email then the user cannot go to the next stage. This goes alongside isDataMissing()
  // DESKTOP
  // just checks whether data is missing in reference to expectedValuesObject
  useEffect(() => {
    // are there inputs (or fields) on the page
    const inputsOnPage =
      reduxStage === "2.1" ||
      reduxStage === "2.2" ||
      reduxStage === "3.1" ||
      reduxStage === "3.4" ||
      reduxStage === "3.7" ||
      reduxStage === "4";
    let defDesktop = !isMobileOnly || props.formData.resizedTo === "desktop";
    // set party B returning to true
    if (!props.formData.partyBReturn && partyBReturn) props.partyBReturn();

    // MOBILE
    // party a
    if (defMobile && !partyBReturn && inputsOnPage) {
      if (reduxStage === "2.1") {
        if (isDataMissing(expectedValuesObject.stage21ObjectPartyA)) {
          props.userCannotGoNext();
        } else if (!isDataMissing(expectedValuesObject.stage21ObjectPartyA)) {
          props.userCanGoNext();
        }
      } else if (reduxStage === "2.2") {
        if (isDataMissing(expectedValuesObject.stage22Object)) {
          props.userCannotGoNext();
        } else if (!isDataMissing(expectedValuesObject.stage22Object)) {
          props.userCanGoNext();
        }
      }
      // if data is empty or email error is in a or b then user cannot go next
      else if (reduxStage === "3.4") {
        if (
          isDataMissing(expectedValuesObject.stage34Object) ||
          emailErr.emailA
        ) {
          props.userCannotGoNext();
        } else if (
          !isDataMissing(expectedValuesObject.stage34Object) &&
          !emailErr.emailA
        ) {
          props.userCanGoNext();
        }
      } else if (reduxStage === "3.7") {
        if (isDataMissing(expectedValuesObject.stage37Object)) {
          props.userCannotGoNext();
        } else if (!isDataMissing(expectedValuesObject.stage37Object)) {
          props.userCanGoNext();
        }
      } else if (reduxStage === "4") {
        if (
          isDataMissing(expectedValuesObject.stage4ObjectPartyA) ||
          emailErr.emailA
        ) {
          props.userCannotGoNext();
        } else if (
          !isDataMissing(expectedValuesObject.stage4ObjectPartyA) &&
          !emailErr.emailA
        ) {
          props.userCanGoNext();
        }
      }
      // MOBILE
      // party b
    } else if (defMobile && partyBReturn && inputsOnPage) {
      if (reduxStage === "2.1") {
        if (isDataMissing(expectedValuesObject.stage21ObjectPartyB)) {
          props.userCannotGoNext();
        } else if (!isDataMissing(expectedValuesObject.stage21ObjectPartyB)) {
          props.userCanGoNext();
        }
      } else if (reduxStage === "3.1") {
        if (isDataMissing(expectedValuesObject.stage31Object)) {
          props.userCannotGoNext();
        } else if (!isDataMissing(expectedValuesObject.stage31Object)) {
          props.userCanGoNext();
        }
      } else if (reduxStage === "3.4") {
        if (
          isDataMissing(expectedValuesObject.stage34ObjectPartyB) ||
          emailError
        ) {
          props.userCannotGoNext();
        } else if (
          !isDataMissing(expectedValuesObject.stage34ObjectPartyB) &&
          !emailError
        ) {
          props.userCanGoNext();
        }
      } else if (reduxStage === "4") {
        if (
          isDataMissing(expectedValuesObject.stage4ObjectPartyB) ||
          emailErrorSignature
        ) {
          props.userCannotGoNext();
        } else if (
          !isDataMissing(expectedValuesObject.stage4ObjectPartyB) &&
          !emailErrorSignature
        ) {
          props.userCanGoNext();
        }
      }
      // IF DESKTOP
    } else if (defDesktop) {
      if (!partyBReturn) {
        if (
          isDataMissing(expectedValuesObject.partyADesktop) ||
          emailErr.emailA ||
          emailErrorSignature
        ) {
          props.userCannotGoNext();
        } else if (
          !isDataMissing(expectedValuesObject.partyADesktop) &&
          !emailErr.emailA &&
          !emailErrorSignature
        ) {
          props.userCanGoNext();
        }
      } else if (partyBReturn) {
        if (
          isDataMissing(expectedValuesObject.partyBDesktop) ||
          emailError ||
          emailErrorSignature
        ) {
          props.userCannotGoNext();
        } else if (
          !isDataMissing(expectedValuesObject.partyBDesktop) &&
          !emailError &&
          !emailErrorSignature
        ) {
          props.userCanGoNext();
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.stageData.stage,
    loading,
    values,
    props.formData.userClickedNextInputsOnPage,
    emailError,
    emailErrorSignature,
    props.stageData.contractActive,
    isMobileOnly,
    props.formData.resizedTo,
  ]);

  useEffect(() => {
    if (values.nationalityA || values.nationalityB) props.formItemBlur();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  // DESKTOP (disable next and display errors)
  const handleSubmitDesktop = () => {
    props.userClickedNextInputsOnPage();

    if (props.formData.userCanGoNext) {
      props.switchToAgreement();
      props.activeAgreement();
      scrollToTop();
      postPutForm();
    }
  };

  // show popup on agreement section
  useEffect(() => {
    if (props.stageData.popupActive) {
      setTimeout(() => {
        setPopupActive(true);
      }, 1500);
    }
  }, [props.stageData.popupActive]);

  // on desktop show the slides necessary
  useEffect(() => {
    // add show
    document.querySelectorAll("section.active").forEach((section) => {
      section.parentElement.classList.add("show");
      section.parentElement.classList.remove("hide");
    });
    // remove show
    document.querySelectorAll("section.hide").forEach((section) => {
      section.parentElement.classList.remove("show");
      section.parentElement.classList.add("hide");
    });
  }, [
    props.stageData.contractActive,
    props.stageData.agreementActive,
    props.stageData.startActive,
  ]);

  // if screen changes size then set mobile active or desktop active depending on screen size
  useEffect(() => {
    const handleResize = (event) => {
      // to mobile
      if (props.formData.resizedTo !== "mobile" && window.innerWidth <= 600) {
        props.setResizedToMobile();
        // to desktop
      } else if (
        props.formData.resizedTo === "mobile" &&
        window.innerWidth > 600
      ) {
        props.setResizedToDesktop();
      }
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [props]);

  // on desktop show the slides necessary
  useEffect(() => {
    // add show
    document.querySelectorAll("section.active").forEach((section) => {
      section.parentElement.classList.add("show");
      section.parentElement.classList.remove("hide");
    });
    // remove show
    document.querySelectorAll("section.hide").forEach((section) => {
      section.parentElement.classList.remove("show");
      section.parentElement.classList.add("hide");
    });
  }, [
    props.stageData.contractActive,
    props.stageData.agreementActive,
    props.stageData.startActive,
  ]);

  // mobile safari so that when the user rotates the phone and returns to portrait, then the entire slide is showing (before there was a small gap at the bottom)
  useEffect(() => {
    if (isMobileSafari) {
      document.getElementsByTagName("html")[0].classList.add("mobile-safari");
    }
  }, []);

  useEffect(() => {
    if (!props.stageData.contractActive)
      document
        .getElementsByTagName("body")[0]
        .classList.add("start-agree-active");
    else
      document
        .getElementsByTagName("body")[0]
        .classList.remove("start-agree-active");
  }, [props.stageData.contractActive]);

  // this object is for seeing whether the user has any empty fields on the current slide (if mobile)/on the current page (if desktop)
  // depending on whether the user is on mobile/desktop will determine which part of the object will be sent to isDataMissing
  // isDataMissing will be called when the user clicks one of the submit buttons (the next button on the slideshow on mobile, or the submit form button at the bottom of the page on tablet/desktop)
  // MOBILE
  // PARTY A FILLING
  let expectedValuesObject = {
    stage21ObjectPartyA: {
      compNameA: values.compNameA,
      compTypeA: values.compTypeA,
      nationalityA: values.nationalityA,
      addressA: values.addressA,
      repNameA: values.repNameA,
      positionA: values.positionA,
    },
    stage22Object: {
      projectName: values.projectName,
      startDate: values.startDate,
      endDate: values.endDate,
      noticeExpDate: values.noticeExpDate,
    },
    stage34Object: {
      contactNameA: values.contactNameA,
      emailA: values.emailA,
    },
    stage37Object: {
      courts: values.courts,
      legalRegulations: values.legalRegulations,
    },
    stage4ObjectPartyA: {
      emailASign: values.emailASign,
      emailBSign: values.emailBSign,
      contactNameASign: values.contactNameASign,
      contactNameBSign: values.contactNameBSign,
      positionASign: values.positionASign,
    },
    // PARTY B FILLING
    stage21ObjectPartyB: {
      compNameA: values.compNameA,
      compTypeA: values.compTypeA,
      nationalityA: values.nationalityA,
      addressA: values.addressA,
      repNameA: values.repNameA,
      positionA: values.positionA,

      compNameB: values.compNameB,
      compTypeB: values.compTypeB,
      nationalityB: values.nationalityB,
      addressB: values.addressB,
      repNameB: values.repNameB,
      positionB: values.positionB,
    },
    stage34ObjectPartyB: {
      contactNameA: values.contactNameA,
      emailA: values.emailA,
      contactNameB: values.contactNameB,
      emailB: values.emailB,
    },
    stage4ObjectPartyB: {
      emailASign: values.emailASign,
      emailBSign: values.emailBSign,
      emailCSign: values.emailCSign,
      contactNameASign: values.contactNameASign,
      contactNameBSign: values.contactNameBSign,
      contactNameCSign: values.contactNameCSign,
      positionASign: values.positionASign,
      positionCSign: values.positionCSign,
    },
    // DESKTOP
    partyADesktop: {
      compNameA: values.compNameA,
      compTypeA: values.compTypeA,
      nationalityA: values.nationalityA,
      addressA: values.addressA,
      repNameA: values.repNameA,
      positionA: values.positionA,

      projectName: values.projectName,
      startDate: values.startDate,
      endDate: values.endDate,
      noticeExpDate: values.noticeExpDate,

      courts: values.courts,
      legalRegulations: values.legalRegulations,

      contactNameA: values.contactNameA,
      emailA: values.emailA,

      contactNameASign: values.contactNameASign,
      emailASign: values.emailASign,

      contactNameBSign: values.contactNameBSign,
      emailBSign: values.emailBSign,

      contactNameCSign: values.contactNameCSign,
      emailCSign: values.emailCSign,
    },
    partyBDesktop: {
      compNameA: values.compNameA,
      compTypeA: values.compTypeA,
      nationalityA: values.nationalityA,
      addressA: values.addressA,
      repNameA: values.repNameA,
      positionA: values.positionA,
      compNameB: values.compNameB,
      compTypeB: values.compTypeB,
      nationalityB: values.nationalityB,
      addressB: values.addressB,
      repNameB: values.repNameB,
      positionB: values.positionB,

      projectName: values.projectName,
      startDate: values.startDate,
      noticeExpDate: values.noticeExpDate,
      endDate: values.endDate,

      contactNameA: values.contactNameA,
      emailA: values.emailA,
      contactNameB: values.contactNameB,
      emailB: values.emailB,

      courts: values.courts,
      legalRegulations: values.legalRegulations,

      contactNameASign: values.contactNameASign,
      emailASign: values.emailASign,
      positionASign: values.positionASign,
      contactNameBSign: values.contactNameBSign,
      emailBSign: values.emailBSign,
      positionBSign: values.positionBSign,
      contactNameCSign: values.contactNameCSign,
      positionCSign: values.positionCSign,
      emailCSign: values.emailCSign,
    },
  };

  // this function is used to see whether the user has any missing fields
  const isDataMissing = (stageObjectToCheck) => {
    let fieldEmptyArray = [];

    // Object.entries polyfill for IE
    if (!Object.entries)
      Object.entries = function (stageObjectToCheck) {
        var ownProps = Object.keys(stageObjectToCheck),
          i = ownProps.length,
          resArray = new Array(i); // preallocate the Array
        while (i--)
          resArray[i] = [ownProps[i], stageObjectToCheck[ownProps[i]]];

        return resArray;
      };

    for (let [key, value] of Object.entries(stageObjectToCheck)) {
      if (
        value === undefined ||
        value === null ||
        (key === "repNameA" && value === "placeholder") ||
        (key === "contactNameASign" && value === "placeholder") ||
        (key === "contactNameCSign" && value === "placeholder")
      ) {
        fieldEmptyArray.push(key);
      } else if (value.length === 0) {
        fieldEmptyArray.push(key);
      }
    }

    if (fieldEmptyArray.length > 0) return true;
    else return false;
  };

  // post/put to form
  const postPutForm = () => {
    let startDate = "";
    if (values.startDate && isMobile) startDate = values.startDate;
    else if (values.startDate && !isMobile)
      startDate = values.startDate.toISOString().split("T")[0];

    const data = {
      partyAinfo: {
        company: values.compNameA ? values.compNameA : null,
        companyType: values.compTypeA ? values.compTypeA : "",
        country: values.nationalityA ? values.nationalityA : "",
        address: values.addressA ? values.addressA : "",
        name: values.repNameA ? values.repNameA : "",
        position: values.positionA ? values.positionA : "",
      },
      partyBinfo: {
        company: values.compNameB ? values.compNameB : null,
        companyType: values.compTypeB ? values.compTypeB : "",
        country: values.nationalityB ? values.nationalityB : "",
        address: values.addressB ? values.addressB : "",
        name: values.repNameB ? values.repNameB : "",
        position: values.positionB ? values.positionB : "",
      },
      partyAcontact: {
        name: values.contactNameA ? values.contactNameA : "",
        email: values.emailA ? values.emailA : "",
      },
      partyBcontact: {
        name: values.contactNameB ? values.contactNameB : "",
        email: values.emailB ? values.emailB : "",
      },
      partyAsignature: {
        name: values.contactNameASign ? values.contactNameASign : "",
        position: values.positionASign ? values.positionASign : "",
        email: values.emailASign ? values.emailASign : "",
      },
      partyBsignature: {
        name: values.contactNameBSign ? values.contactNameBSign : "",
        position: values.positionBSign ? values.positionBSign : "",
        email: values.emailBSign ? values.emailBSign : "",
      },
      partyCsignature: {
        name: values.contactNameCSign ? values.contactNameCSign : "",
        position: values.positionCSign ? values.positionCSign : "",
        email: values.emailCSign ? values.emailCSign : "",
      },
      project: values.projectName ? values.projectName : "",
      startingDate: startDate,
      endDate: values.endDate ? values.endDate.toISOString().split("T")[0] : "",
      daysPriorNotice: values.noticeExpDate ? values.noticeExpDate : "",
      courtJurisdiction: values.courts ? values.courts : "",
      applicableLaw: values.legalRegulations ? values.legalRegulations : "",
    };
    setLoading(true);
    // post request
    if (path === "/") {
      axios
        .post(process.env.REACT_APP_API_PATH + config.apiCreateContract, data)
        .then(function (response) {
          setLoading(false);
          props.formSent();
        })
        .catch(function (error) {
          setLoading(false);
        });
      // put request
    } else {
      axios
        .put(
          process.env.REACT_APP_API_PATH +
            config.apiUpdateContract +
            path +
            "/" +
            password,
          data
        )
        .then(function (response) {
          setLoading(false);
          props.formSent();
        })
        .catch(function (error) {
          setLoading(false);
        });
    }
  };

  const inputsOnPage = () =>
    reduxStage === "2.1" ||
    reduxStage === "2.2" ||
    reduxStage === "3.1" ||
    reduxStage === "3.4" ||
    reduxStage === "3.7" ||
    reduxStage === "4";

  const contactNameBError = () => {
    let nextClickDtop = defDesktop && nextJustClicked;
    return (
      (reduxStage === "3.4" &&
        !values.contactNameB &&
        nextJustClicked &&
        partyBReturn) ||
      (nextClickDtop && !values.contactNameB && partyBReturn)
    );
  };

  const repNameASignError = () => {
    let nextClickDtop = defDesktop && nextJustClicked;
    return (
      (reduxStage === "4" && !values.repNameA && nextJustClicked) ||
      (reduxStage === "4" &&
        values.repNameA === "placeholder" &&
        nextJustClicked) ||
      (nextClickDtop && !values.repNameA) ||
      (nextClickDtop && values.repNameA === "placeholder")
    );
  };

  const contactNameBSignError = () => {
    let nextClickDtop = defDesktop && nextJustClicked;
    return (
      (reduxStage === "4" && !values.contactNameBSign && nextJustClicked) ||
      (nextClickDtop && !values.contactNameBSign)
    );
  };

  const repNameCSignError = () => {
    return (
      (reduxStage === "4" && !values.contactNameCSign && nextJustClicked) ||
      (reduxStage === "4" &&
        values.contactNameCSign === "placeholder" &&
        nextJustClicked) ||
      (nextClickDtop && !values.contactNameCSign) ||
      (nextClickDtop && values.contactNameCSign === "placeholder")
    );
  };

  return (
    <>
      <Header
        slideTo={(id) => {
          setSlideToId(id);
        }}
        main={mainSect}
        fillInfoStart={fillInfoStart}
        readCarefulStart={readCarefulStart}
        signatureStart={signatureStart}
        agreeSect={agreeSect}
        parallaxRef={parallaxRef}
      />
      <div className="landscape-error">
        <img src="/rotate.png" alt="rotate-your-phone" />
      </div>

      <SpinningLoader active={loading ? true : false} />

      <main>
        <div className="wrapper">
          <div
            className={
              "viewport" +
              (props.stageData.contractActive
                ? " contract-viewport"
                : " start-agree-viewport")
            }
          >
            {defDesktop() && (
              <div
                className={
                  "desktop-bg-shapes" +
                  (props.stageData.contractActive
                    ? " contract"
                    : " start-agree")
                }
              >
                <span className="dotted-shape dotted-red-background left"></span>
                <span className="dotted-shape dotted-red-background right"></span>
                {isIE ? (
                  <>
                    <span className="dotted-shape dotted-red-background ie11 contract"></span>
                    <span className="dotted-shape dotted-light-blue-background one ie11"></span>
                    <span className="dotted-shape dotted-black-background ie11"></span>
                    <span className="dotted-shape dotted-light-blue-background two ie11"></span>
                  </>
                ) : (
                  <BackgroundParallax />
                )}
              </div>
            )}

            <Swiper
              onSwiper={(swiper) => setSwiper(swiper)}
              allowTouchMove={false}
              initialSlide={0}
              autoHeight
              spaceBetween={600}
              slidesPerView={1}
            >
              <SwiperSlide key={0}>
                <section
                  className={
                    "start" +
                    (props.stageData.startActive ? " active" : " hide")
                  }
                >
                  <span className="dotted-red-background mobile"></span>
                  <div className="rectangle-item">
                    <span className="desktop rectangle rectangle-1 blue"></span>
                  </div>
                  <div className="wrapper-container">
                    <ModalContainer
                      error={passwordError}
                      active={path !== "/" ? true : false}
                      loading={loading}
                      onClick={checkPassword}
                      handleChange={handlePasswordChange}
                      modalShowing={modalShowing}
                      value={password || ""}
                    />

                    <div className="quote-header">
                      <h1>
                        Confidentiality <br /> Agreement
                      </h1>
                    </div>

                    <div className="content">
                      <span className="hello">Hello,</span>
                      <p className="subtitle">
                        You're considering working with us ? Here what you need
                        to know about which information is confidential and how
                        we each must protect it.
                      </p>

                      <div className="container">
                        <div className="processes">
                          <div className="process process-1">
                            <p>
                              User A<br />
                              fills info
                            </p>
                          </div>
                          <div className="process process-2">
                            <p>
                              Email sent
                              <br />
                              to User B
                            </p>
                          </div>
                          <div className="process process-3">
                            <p>
                              User B<br />
                              fills info
                            </p>
                          </div>
                          <div className="process process-4">
                            <p>
                              Certified PDF
                              <br />
                              generated
                            </p>
                          </div>
                          <div className="process process-5">
                            <p>
                              Signature
                              <br />
                              request
                            </p>
                          </div>
                          <div className="process process-6">
                            <p>
                              Signature on
                              <br />
                              the platform
                            </p>
                          </div>
                          <div className="process process-7">
                            <p>
                              Final PDF
                              <br />
                              generated
                            </p>
                          </div>
                        </div>
                        <button
                          onClick={() => {
                            return (
                              props.activeFillInfo(),
                              props.switchToContract(),
                              props.contractSectionForm(),
                              scrollToTop()
                            );
                          }}
                          className="rounded-edge-button"
                        >
                          <span>Start fill-in</span>
                        </button>
                      </div>

                      <div className="agreement-container">
                        <span className="clock-circle"></span>
                        <div className="fill-text">
                          <span>
                            5 minutes to fill-in and sign your Agreement
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </SwiperSlide>

              <SwiperSlide key={1}>
                <section
                  ref={fillInfoStart}
                  className={
                    "part-1" +
                    (props.stageData.contractActive ? " active" : " hide")
                  }
                >
                  <div className="rectangle-item">
                    <span className="mobile rectangle rectangle-1 pink"></span>
                  </div>
                  <div className="wrapper-container">
                    <p className="tips-header">
                      <b>Tips to complete - </b>
                      <BlueUnderlineContainer
                        reduxStage={"2.1"}
                        displayAlways={true}
                        text={"If you are part of SOPHiA GENETICS"}
                      />
                      , you need to fill in 
                      <BlueUnderlineContainer
                        reduxStage={"2.1"}
                        displayAlways={true}
                        text={
                          "all the fields related to SOPHiA, and the project description."
                        }
                      />
                       Please note{" "}
                      <BlueUnderlineContainer
                        reduxStage={"2.1"}
                        displayAlways={true}
                        text={"« You »"}
                      />{" "}
                      is the counterparty. Don’t worry, you’ll be guided all the
                      way thanks to help bubbles.  Before starting,{" "}
                      <BlueUnderlineContainer
                        reduxStage={"2.1"}
                        displayAlways={true}
                        text={
                          "make sure you've got the other party's email and name ready. "
                        }
                      />
                      <br />
                      <br />
                      <BlueUnderlineContainer
                        reduxStage={"2.1"}
                        displayAlways={true}
                        text={
                          "If you are receiving this NDA from SOPHiA GENETICS,"
                        }
                      />{" "}
                      you can{" "}
                      <BlueUnderlineContainer
                        reduxStage={"2.1"}
                        displayAlways={true}
                        text={
                          "freely edit all the fields related to your company (« You ») and the project description."
                        }
                      />{" "}
                      Please note that you 
                      <BlueUnderlineContainer
                        reduxStage={"2.1"}
                        displayAlways={true}
                        text={"cannot edit the other fields."}
                      />{" "}
                      But we've made the contract so clear and balanced that we
                      trust it shouldn't be a problem! 
                    </p>
                    <div className="quote-header desktop">
                      <h2>
                        This confidentiality agreement (this{" "}
                        <strong>"Agreement"</strong>) is made between:
                      </h2>
                    </div>

                    <div className="parties-info-wrapper">
                      <div className="parties-info">
                        <div className="circle dark-blue xl-desktop">
                          <span>&</span>
                        </div>
                        <div className="circle dark-blue large">
                          <span>&</span>
                        </div>

                        <div className="party part-a">
                          <div
                            className={
                              "form-item comp-name select" +
                              (reduxStage === "2.1" &&
                              !values.compNameA &&
                              nextJustClicked
                                ? " error"
                                : "") +
                              (nextClickDtop && !values.compNameA
                                ? " error"
                                : "")
                            }
                          >
                            <select
                              onFocus={() => {
                                props.formItemFocus();
                              }}
                              onBlur={() => {
                                props.formItemBlur();
                              }}
                              onChange={handleFormChange}
                              value={values.compNameA}
                              name="compNameA"
                              defaultValue="placeholder"
                              disabled={partyBReturn}
                            >
                              <option disabled={true} value="placeholder">
                                Company name
                              </option>
                              {companies.length > 0 &&
                                companies.map((company) => (
                                  <option value={company.name}>
                                    {company.name}
                                  </option>
                                ))}
                            </select>
                          </div>

                          <div className="comp-type-nation">
                            <InputInfoContainer
                              input={"input"}
                              containerClass={"form-item comp-type"}
                              handleFormChange={handleFormChange}
                              value={values.compTypeA || ""}
                              name={"compTypeA"}
                              type={"text"}
                              placeholder={"Type of Company"}
                              hideInfo
                              error={
                                (reduxStage === "2.1" &&
                                  !values.compTypeA &&
                                  nextJustClicked) ||
                                (nextClickDtop && !values.compTypeA)
                              }
                              text={
                                "This is the type legal status of your company, you can usually find it in official documents, mentioned after the name of your company. For instance it can be “private company” or “limited partnership”."
                              }
                              disabled={true}
                            />
                            <div
                              className={
                                "form-item nation client-a" +
                                (reduxStage === "2.1" &&
                                !values.nationalityA &&
                                nextJustClicked
                                  ? " error"
                                  : "") +
                                (nextClickDtop && !values.nationalityA
                                  ? " error"
                                  : "")
                              }
                            >
                              <input
                                type="text"
                                className={
                                  "nationality" +
                                  (values.nationalityA ? " item-selected" : "")
                                }
                                onFocus={() => {
                                  props.formItemFocus();
                                }}
                                onBlur={() => {
                                  props.formItemBlur();
                                }}
                                onChange={handleFormChange}
                                value={values.nationalityA}
                                name="nationalityA"
                                required
                                disabled={true}
                                placeholder="Nationality"
                              />
                            </div>
                          </div>

                          <InputInfoContainer
                            input={"textarea"}
                            containerClass={"form-item address"}
                            handleFormChange={handleFormChange}
                            value={values.addressA || ""}
                            name={"addressA"}
                            placeholder={"Full address of registered office"}
                            error={
                              (reduxStage === "2.1" &&
                                !values.addressA &&
                                nextJustClicked) ||
                              (nextClickDtop && !values.addressA)
                            }
                            hideInfo
                            disabled
                            text={
                              "This the address mentioned in official documents, usually where your headquarters are located."
                            }
                          />

                          <div
                            className={
                              "form-item select" +
                              ((reduxStage === "2.1" &&
                                !values.repNameA &&
                                nextJustClicked) ||
                              (reduxStage === "2.1" &&
                                values.repNameA === "placeholder" &&
                                nextJustClicked)
                                ? " error"
                                : "") +
                              ((nextClickDtop && !values.repNameA) ||
                              (nextClickDtop &&
                                values.repNameA === "placeholder")
                                ? " error"
                                : "")
                            }
                          >
                            <select
                              onFocus={() => {
                                props.formItemFocus();
                              }}
                              onBlur={() => {
                                props.formItemBlur();
                              }}
                              onChange={handleFormChange}
                              value={values.repNameA}
                              name="repNameA"
                              defaultValue="placeholder"
                              disabled={partyBReturn || !values.compNameA}
                            >
                              <option disabled value="placeholder">
                                Representative Name
                              </option>
                              {repNameAOptions &&
                                repNameAOptions.map((option) => (
                                  <option value={option.name}>
                                    {option.name}
                                  </option>
                                ))}
                            </select>
                          </div>

                          <div
                            className={
                              "form-item remove-margin" +
                              (reduxStage === "2.1" &&
                              !values.positionA &&
                              nextJustClicked
                                ? " error"
                                : "") +
                              (nextClickDtop && !values.positionA
                                ? " error"
                                : "")
                            }
                          >
                            <input
                              onFocus={() => {
                                props.formItemFocus();
                              }}
                              onBlur={() => {
                                props.formItemBlur();
                              }}
                              onChange={handleFormChange}
                              value={values.positionA || ""}
                              name="positionA"
                              type="text"
                              placeholder="Position"
                              disabled={true}
                            />
                          </div>
                        </div>

                        <div className="party part-b">
                          <div
                            className={
                              "form-item comp-name" +
                              (reduxStage === "2.1" &&
                              !values.compNameB &&
                              nextJustClicked &&
                              partyBReturn
                                ? " error"
                                : "") +
                              (nextClickDtop &&
                              !values.compNameB &&
                              partyBReturn
                                ? " error"
                                : "")
                            }
                          >
                            <input
                              onFocus={() => {
                                props.formItemFocus();
                              }}
                              onBlur={() => {
                                props.formItemBlur();
                              }}
                              onChange={handleFormChange}
                              value={values.compNameB || ""}
                              name="compNameB"
                              type="text"
                              placeholder="Company name"
                            />
                          </div>

                          <div className="comp-type-nation">
                            <InputInfoContainer
                              input={"input"}
                              containerClass={"form-item comp-type"}
                              handleFormChange={handleFormChange}
                              value={values.compTypeB || ""}
                              name={"compTypeB"}
                              type={"text"}
                              placeholder={"Type of Company"}
                              error={
                                (reduxStage === "2.1" &&
                                  !values.compTypeB &&
                                  nextJustClicked &&
                                  partyBReturn) ||
                                (nextClickDtop &&
                                !values.compTypeB &&
                                partyBReturn
                                  ? " error"
                                  : "")
                              }
                              text={
                                "This is the type legal status of your company, you can usually find it in official documents, mentioned after the name of your company. For instance it can be “private company” or “limited partnership”."
                              }
                            />

                            <div
                              className={
                                "form-item nation select" +
                                (reduxStage === "2.1" &&
                                !values.nationalityB &&
                                nextJustClicked &&
                                partyBReturn
                                  ? " error"
                                  : "") +
                                (nextClickDtop &&
                                !values.nationalityB &&
                                partyBReturn
                                  ? " error"
                                  : "")
                              }
                            >
                              <select
                                className={
                                  values.nationalityB ? " item-selected" : ""
                                }
                                onFocus={() => {
                                  props.formItemFocus();
                                }}
                                onBlur={() => {
                                  props.formItemBlur();
                                }}
                                onChange={handleFormChange}
                                value={values.nationalityB}
                                name="nationalityB"
                                defaultValue="nation"
                                required
                              >
                                <option value="nation" disabled={true}>
                                  Nationality
                                </option>
                                <option value="Switzerland">Switzerland</option>
                                <option value="USA">USA</option>
                                <option value="France">France</option>
                                <option value="Afganistan">Afghanistan</option>
                                <option value="Albania">Albania</option>
                                <option value="Algeria">Algeria</option>
                                <option value="American Samoa">
                                  American Samoa
                                </option>
                                <option value="Andorra">Andorra</option>
                                <option value="Angola">Angola</option>
                                <option value="Anguilla">Anguilla</option>
                                <option value="Antigua & Barbuda">
                                  Antigua & Barbuda
                                </option>
                                <option value="Argentina">Argentina</option>
                                <option value="Armenia">Armenia</option>
                                <option value="Aruba">Aruba</option>
                                <option value="Australia">Australia</option>
                                <option value="Austria">Austria</option>
                                <option value="Azerbaijan">Azerbaijan</option>
                                <option value="Bahamas">Bahamas</option>
                                <option value="Bahrain">Bahrain</option>
                                <option value="Bangladesh">Bangladesh</option>
                                <option value="Barbados">Barbados</option>
                                <option value="Belarus">Belarus</option>
                                <option value="Belgium">Belgium</option>
                                <option value="Belize">Belize</option>
                                <option value="Benin">Benin</option>
                                <option value="Bermuda">Bermuda</option>
                                <option value="Bhutan">Bhutan</option>
                                <option value="Bolivia">Bolivia</option>
                                <option value="Bonaire">Bonaire</option>
                                <option value="Bosnia & Herzegovina">
                                  Bosnia & Herzegovina
                                </option>
                                <option value="Botswana">Botswana</option>
                                <option value="Brazil">Brazil</option>
                                <option value="British Indian Ocean Ter">
                                  British Indian Ocean Ter
                                </option>
                                <option value="Brunei">Brunei</option>
                                <option value="Bulgaria">Bulgaria</option>
                                <option value="Burkina Faso">
                                  Burkina Faso
                                </option>
                                <option value="Burundi">Burundi</option>
                                <option value="Cambodia">Cambodia</option>
                                <option value="Cameroon">Cameroon</option>
                                <option value="Canada">Canada</option>
                                <option value="Canary Islands">
                                  Canary Islands
                                </option>
                                <option value="Cape Verde">Cape Verde</option>
                                <option value="Cayman Islands">
                                  Cayman Islands
                                </option>
                                <option value="Central African Republic">
                                  Central African Republic
                                </option>
                                <option value="Chad">Chad</option>
                                <option value="Channel Islands">
                                  Channel Islands
                                </option>
                                <option value="Chile">Chile</option>
                                <option value="China">China</option>
                                <option value="Christmas Island">
                                  Christmas Island
                                </option>
                                <option value="Cocos Island">
                                  Cocos Island
                                </option>
                                <option value="Colombia">Colombia</option>
                                <option value="Comoros">Comoros</option>
                                <option value="Congo">Congo</option>
                                <option value="Cook Islands">
                                  Cook Islands
                                </option>
                                <option value="Costa Rica">Costa Rica</option>
                                <option value="Cote DIvoire">
                                  Cote DIvoire
                                </option>
                                <option value="Croatia">Croatia</option>
                                <option value="Cuba">Cuba</option>
                                <option value="Curaco">Curacao</option>
                                <option value="Cyprus">Cyprus</option>
                                <option value="Czech Republic">
                                  Czech Republic
                                </option>
                                <option value="Denmark">Denmark</option>
                                <option value="Djibouti">Djibouti</option>
                                <option value="Dominica">Dominica</option>
                                <option value="Dominican Republic">
                                  Dominican Republic
                                </option>
                                <option value="East Timor">East Timor</option>
                                <option value="Ecuador">Ecuador</option>
                                <option value="Egypt">Egypt</option>
                                <option value="El Salvador">El Salvador</option>
                                <option value="Equatorial Guinea">
                                  Equatorial Guinea
                                </option>
                                <option value="Eritrea">Eritrea</option>
                                <option value="Estonia">Estonia</option>
                                <option value="Ethiopia">Ethiopia</option>
                                <option value="Falkland Islands">
                                  Falkland Islands
                                </option>
                                <option value="Faroe Islands">
                                  Faroe Islands
                                </option>
                                <option value="Fiji">Fiji</option>
                                <option value="Finland">Finland</option>
                                <option value="French Guiana">
                                  French Guiana
                                </option>
                                <option value="French Polynesia">
                                  French Polynesia
                                </option>
                                <option value="French Southern Ter">
                                  French Southern Ter
                                </option>
                                <option value="Gabon">Gabon</option>
                                <option value="Gambia">Gambia</option>
                                <option value="Georgia">Georgia</option>
                                <option value="Germany">Germany</option>
                                <option value="Ghana">Ghana</option>
                                <option value="Gibraltar">Gibraltar</option>
                                <option value="Great Britain">
                                  Great Britain
                                </option>
                                <option value="Greece">Greece</option>
                                <option value="Greenland">Greenland</option>
                                <option value="Grenada">Grenada</option>
                                <option value="Guadeloupe">Guadeloupe</option>
                                <option value="Guam">Guam</option>
                                <option value="Guatemala">Guatemala</option>
                                <option value="Guinea">Guinea</option>
                                <option value="Guyana">Guyana</option>
                                <option value="Haiti">Haiti</option>
                                <option value="Hawaii">Hawaii</option>
                                <option value="Honduras">Honduras</option>
                                <option value="Hong Kong">Hong Kong</option>
                                <option value="Hungary">Hungary</option>
                                <option value="Iceland">Iceland</option>
                                <option value="Indonesia">Indonesia</option>
                                <option value="India">India</option>
                                <option value="Iran">Iran</option>
                                <option value="Iraq">Iraq</option>
                                <option value="Ireland">Ireland</option>
                                <option value="Isle of Man">Isle of Man</option>
                                <option value="Israel">Israel</option>
                                <option value="Italy">Italy</option>
                                <option value="Jamaica">Jamaica</option>
                                <option value="Japan">Japan</option>
                                <option value="Jordan">Jordan</option>
                                <option value="Kazakhstan">Kazakhstan</option>
                                <option value="Kenya">Kenya</option>
                                <option value="Kiribati">Kiribati</option>
                                <option value="Korea North">Korea North</option>
                                <option value="Korea Sout">Korea South</option>
                                <option value="Kuwait">Kuwait</option>
                                <option value="Kyrgyzstan">Kyrgyzstan</option>
                                <option value="Laos">Laos</option>
                                <option value="Latvia">Latvia</option>
                                <option value="Lebanon">Lebanon</option>
                                <option value="Lesotho">Lesotho</option>
                                <option value="Liberia">Liberia</option>
                                <option value="Libya">Libya</option>
                                <option value="Liechtenstein">
                                  Liechtenstein
                                </option>
                                <option value="Lithuania">Lithuania</option>
                                <option value="Luxembourg">Luxembourg</option>
                                <option value="Macau">Macau</option>
                                <option value="Macedonia">Macedonia</option>
                                <option value="Madagascar">Madagascar</option>
                                <option value="Malaysia">Malaysia</option>
                                <option value="Malawi">Malawi</option>
                                <option value="Maldives">Maldives</option>
                                <option value="Mali">Mali</option>
                                <option value="Malta">Malta</option>
                                <option value="Marshall Islands">
                                  Marshall Islands
                                </option>
                                <option value="Martinique">Martinique</option>
                                <option value="Mauritania">Mauritania</option>
                                <option value="Mauritius">Mauritius</option>
                                <option value="Mayotte">Mayotte</option>
                                <option value="Mexico">Mexico</option>
                                <option value="Midway Islands">
                                  Midway Islands
                                </option>
                                <option value="Moldova">Moldova</option>
                                <option value="Monaco">Monaco</option>
                                <option value="Mongolia">Mongolia</option>
                                <option value="Montserrat">Montserrat</option>
                                <option value="Morocco">Morocco</option>
                                <option value="Mozambique">Mozambique</option>
                                <option value="Myanmar">Myanmar</option>
                                <option value="Nambia">Nambia</option>
                                <option value="Nauru">Nauru</option>
                                <option value="Nepal">Nepal</option>
                                <option value="Netherland Antilles">
                                  Netherland Antilles
                                </option>
                                <option value="Netherlands">Holland</option>
                                <option value="Nevis">Nevis</option>
                                <option value="New Caledonia">
                                  New Caledonia
                                </option>
                                <option value="New Zealand">New Zealand</option>
                                <option value="Nicaragua">Nicaragua</option>
                                <option value="Niger">Niger</option>
                                <option value="Nigeria">Nigeria</option>
                                <option value="Niue">Niue</option>
                                <option value="Norfolk Island">
                                  Norfolk Island
                                </option>
                                <option value="Norway">Norway</option>
                                <option value="Oman">Oman</option>
                                <option value="Pakistan">Pakistan</option>
                                <option value="Palau Island">
                                  Palau Island
                                </option>
                                <option value="Palestine">Palestine</option>
                                <option value="Panama">Panama</option>
                                <option value="Papua New Guinea">
                                  Papua New Guinea
                                </option>
                                <option value="Paraguay">Paraguay</option>
                                <option value="Peru">Peru</option>
                                <option value="Phillipines">Philippines</option>
                                <option value="Pitcairn Island">
                                  Pitcairn Island
                                </option>
                                <option value="Poland">Poland</option>
                                <option value="Portugal">Portugal</option>
                                <option value="Puerto Rico">Puerto Rico</option>
                                <option value="Qatar">Qatar</option>
                                <option value="Republic of Montenegro">
                                  Republic of Montenegro
                                </option>
                                <option value="Republic of Serbia">
                                  Republic of Serbia
                                </option>
                                <option value="Reunion">Reunion</option>
                                <option value="Romania">Romania</option>
                                <option value="Russia">Russia</option>
                                <option value="Rwanda">Rwanda</option>
                                <option value="St Barthelemy">
                                  St Barthelemy
                                </option>
                                <option value="St Eustatius">
                                  St Eustatius
                                </option>
                                <option value="St Helena">St Helena</option>
                                <option value="St Kitts-Nevis">
                                  St Kitts-Nevis
                                </option>
                                <option value="St Lucia">St Lucia</option>
                                <option value="St Maarten">St Maarten</option>
                                <option value="St Pierre & Miquelon">
                                  St Pierre & Miquelon
                                </option>
                                <option value="St Vincent & Grenadines">
                                  St Vincent & Grenadines
                                </option>
                                <option value="Saipan">Saipan</option>
                                <option value="Samoa">Samoa</option>
                                <option value="Samoa American">
                                  Samoa American
                                </option>
                                <option value="San Marino">San Marino</option>
                                <option value="Sao Tome & Principe">
                                  Sao Tome & Principe
                                </option>
                                <option value="Saudi Arabia">
                                  Saudi Arabia
                                </option>
                                <option value="Senegal">Senegal</option>
                                <option value="Seychelles">Seychelles</option>
                                <option value="Sierra Leone">
                                  Sierra Leone
                                </option>
                                <option value="Singapore">Singapore</option>
                                <option value="Slovakia">Slovakia</option>
                                <option value="Slovenia">Slovenia</option>
                                <option value="Solomon Islands">
                                  Solomon Islands
                                </option>
                                <option value="Somalia">Somalia</option>
                                <option value="South Africa">
                                  South Africa
                                </option>
                                <option value="Spain">Spain</option>
                                <option value="Sri Lanka">Sri Lanka</option>
                                <option value="Sudan">Sudan</option>
                                <option value="Suriname">Suriname</option>
                                <option value="Swaziland">Swaziland</option>
                                <option value="Sweden">Sweden</option>
                                <option value="Syria">Syria</option>
                                <option value="Tahiti">Tahiti</option>
                                <option value="Taiwan">Taiwan</option>
                                <option value="Tajikistan">Tajikistan</option>
                                <option value="Tanzania">Tanzania</option>
                                <option value="Thailand">Thailand</option>
                                <option value="Togo">Togo</option>
                                <option value="Tokelau">Tokelau</option>
                                <option value="Tonga">Tonga</option>
                                <option value="Trinidad & Tobago">
                                  Trinidad & Tobago
                                </option>
                                <option value="Tunisia">Tunisia</option>
                                <option value="Turkey">Turkey</option>
                                <option value="Turkmenistan">
                                  Turkmenistan
                                </option>
                                <option value="Turks & Caicos Is">
                                  Turks & Caicos Is
                                </option>
                                <option value="Tuvalu">Tuvalu</option>
                                <option value="Uganda">Uganda</option>
                                <option value="Ukraine">Ukraine</option>
                                <option value="United Arab Erimates">
                                  United Arab Emirates
                                </option>
                                <option value="United Kingdom">
                                  United Kingdom
                                </option>
                                <option value="Uraguay">Uruguay</option>
                                <option value="Uzbekistan">Uzbekistan</option>
                                <option value="Vanuatu">Vanuatu</option>
                                <option value="Vatican City State">
                                  Vatican City State
                                </option>
                                <option value="Venezuela">Venezuela</option>
                                <option value="Vietnam">Vietnam</option>
                                <option value="Virgin Islands (Brit)">
                                  Virgin Islands (Brit)
                                </option>
                                <option value="Virgin Islands (USA)">
                                  Virgin Islands (USA)
                                </option>
                                <option value="Wake Island">Wake Island</option>
                                <option value="Wallis & Futana Is">
                                  Wallis & Futana Is
                                </option>
                                <option value="Yemen">Yemen</option>
                                <option value="Zaire">Zaire</option>
                                <option value="Zambia">Zambia</option>
                                <option value="Zimbabwe">Zimbabwe</option>
                              </select>
                            </div>
                          </div>

                          <InputInfoContainer
                            input={"textarea"}
                            containerClass={"form-item address"}
                            handleFormChange={handleFormChange}
                            value={values.addressB || ""}
                            error={
                              (reduxStage === "2.1" &&
                                !values.addressB &&
                                nextJustClicked &&
                                partyBReturn) ||
                              (nextClickDtop &&
                                !values.addressB &&
                                partyBReturn)
                            }
                            name={"addressB"}
                            placeholder={"Full address of registered office"}
                            text={
                              "This the address mentioned in official documents, usually where your headquarters are located."
                            }
                          />

                          <div
                            className={
                              "form-item" +
                              (reduxStage === "2.1" &&
                              !values.repNameB &&
                              nextJustClicked &&
                              partyBReturn
                                ? " error"
                                : "") +
                              (nextClickDtop && !values.repNameB && partyBReturn
                                ? " error"
                                : "")
                            }
                          >
                            <input
                              onFocus={() => {
                                props.formItemFocus();
                              }}
                              onBlur={() => {
                                props.formItemBlur();
                              }}
                              onChange={handleFormChange}
                              value={values.repNameB || ""}
                              name="repNameB"
                              type="text"
                              placeholder="Representative Name"
                            />
                          </div>

                          <div
                            className={
                              "form-item remove-margin" +
                              (reduxStage === "2.1" &&
                              !values.positionB &&
                              nextJustClicked &&
                              partyBReturn
                                ? " error"
                                : "") +
                              (nextClickDtop &&
                              !values.positionB &&
                              partyBReturn
                                ? " error"
                                : "")
                            }
                          >
                            <input
                              onFocus={() => {
                                props.formItemFocus();
                              }}
                              onBlur={() => {
                                props.formItemBlur();
                              }}
                              onChange={handleFormChange}
                              value={values.positionB || ""}
                              name="positionB"
                              type="text"
                              placeholder="Position"
                            />
                          </div>
                        </div>
                      </div>
                      <p>
                        Collectively, "
                        <BlueUnderlineContainer
                          reduxStage={"2.1"}
                          bold
                          text={"the Parties"}
                        />
                        " (or "
                        <BlueUnderlineContainer
                          reduxStage={"2.1"}
                          bold
                          text={"we"}
                        />
                        ") and individually, "
                        <BlueUnderlineContainer
                          reduxStage={"2.1"}
                          bold
                          text={"the Party"}
                        />
                        ".
                      </p>
                      <p className="agreement-and-affiliates">
                        This Agreement is applicable to both the Parties and
                        their affiliates.
                      </p>
                    </div>
                  </div>
                </section>
              </SwiperSlide>

              <SwiperSlide key={2}>
                <section
                  className={
                    "part-2" +
                    (props.stageData.contractActive ? " active" : " hide")
                  }
                >
                  <div className="rectangle-item">
                    <span className="mobile rectangle rectangle-1 pink"></span>
                  </div>

                  <span className="dotted-black-background desktop"></span>
                  <div className="wrapper-container">
                    <h2>The Parties are considering working together on:</h2>

                    <div className="start-project-container">
                      <InputInfoContainer
                        input={"textarea"}
                        containerClass={"form-item the-project"}
                        maxLength={350}
                        handleFormChange={handleFormChange}
                        value={values.projectName || ""}
                        error={
                          (reduxStage === "2.2" &&
                            !values.projectName &&
                            nextJustClicked) ||
                          (nextClickDtop && !values.projectName)
                        }
                        name={"projectName"}
                        placeholder={
                          "Type here the project description\na potential research relationship\na potential business relationship\na potential partnership relating to …"
                        }
                        text={
                          "The project needs to be carefully defined, to make sure the information you share are used for the right purpose. This should explain in a few lines the project or the objective directing the exchange of information."
                        }
                        disabled={partyBReturn}
                      />

                      <h2 className="agreement-start-header">
                        This Agreement starts on:
                      </h2>

                      <div className="form-date-wrapper">
                        <div className="rectangle-item">
                          <span className="desktop rectangle rectangle-1 pink"></span>
                        </div>
                        <div
                          className={
                            "form-item date-input" +
                            (isIE ? " ie11" : "") +
                            (props.formData.resizedTo === "mobile"
                              ? " resized-mobile"
                              : "")
                          }
                        >
                          {!defDesktop() ? (
                            <div
                              className={
                                "error-input-wrap" +
                                (reduxStage === "2.2" &&
                                !values.startDate &&
                                nextJustClicked
                                  ? " error"
                                  : "")
                              }
                            >
                              <input
                                onFocus={() => {
                                  props.formItemFocus();
                                }}
                                onBlur={() => {
                                  props.formItemBlur();
                                }}
                                onChange={handleFormChange}
                                value={values.startDate}
                                name="startDate"
                                type="date"
                                placeholder="dd/mm/yyyy"
                                disabled={partyBReturn}
                              />
                            </div>
                          ) : (
                            <>
                              <div
                                className={
                                  "error-input-wrap" +
                                  (nextClickDtop && !values.startDate
                                    ? " error"
                                    : "")
                                }
                              >
                                <DatePicker
                                  selected={values.startDate}
                                  onChange={handleStartDateDesk}
                                  placeholderText={"dd/mm/yyyy"}
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  dateFormat="dd/MM/yyyy"
                                  openToDate={new Date()}
                                  popperModifiers={{
                                    preventOverflow: {
                                      enabled: true,
                                    },
                                  }}
                                  readOnly={partyBReturn}
                                />
                              </div>
                              <span className="starting-date-label">
                                Starting date:
                              </span>
                            </>
                          )}

                          <span className="three-years">
                            for <strong>3 years</strong>
                          </span>
                        </div>

                        <div className="end-date-project-container">
                          <span className="circle dark-blue medium arrow">
                            <span className="end-text">End</span>
                          </span>
                          {values.endDate ? (
                            <h2 className="date-text">
                              {values.endDate.toLocaleDateString("en-GB")}
                            </h2>
                          ) : (
                            <h2 className="date-text">dd/mm/yyyy</h2>
                          )}
                        </div>
                      </div>

                      <div className="termination-date">
                        <p>
                          Either Party can terminate this Agreement at any time,
                          by informing the other Party with a:
                        </p>

                        <InputInfoContainer
                          input={"number"}
                          containerClass={"form-item days-prior-notice"}
                          handleFormChange={handleFormChange}
                          value={values.noticeExpDate || ""}
                          error={
                            (reduxStage === "2.2" &&
                              !values.noticeExpDate &&
                              nextJustClicked) ||
                            (nextClickDtop && !values.noticeExpDate)
                          }
                          name={"noticeExpDate"}
                          placeholder={"e.g. 30"}
                          min={"1"}
                          max={"100"}
                          disabled={partyBReturn}
                          hideInfo
                          className={"termination-date-input"}
                          labelHtml={"termination-date"}
                          labelText={"days prior written notice"}
                          labelFontWeight={"bold"}
                        />
                      </div>
                    </div>

                    <p>
                      Clauses{" "}
                      <BlueUnderlineContainer
                        reduxStage={"2.2"}
                        text={"1, 2, 3, 5, 6, 7"}
                      />{" "}
                      will remain in force for 3 years after this Agreement ends
                      or for the duration of protection applicable to the
                      relevant types of Confidential Information, such as trade
                      secrets, know-how, etc., whichever is longer.
                    </p>
                  </div>
                </section>
              </SwiperSlide>

              <SwiperSlide key={3}>
                <section
                  ref={readCarefulStart}
                  className={
                    "part one" +
                    (props.stageData.contractActive ? " active" : " hide")
                  }
                >
                  <div className="rectangle-item">
                    <span className="desktop rectangle rectangle-1 pink"></span>
                  </div>
                  <div className="rectangle-item">
                    <span className="mobile rectangle rectangle-1 pink"></span>
                  </div>
                  <div className="wrapper-container">
                    <h2>
                      Our mutual commitment : protecting our respective
                      Confidential Information.
                    </h2>

                    <div className="title-read-container">
                      <span className="number">1</span>
                      <h3 className="title">
                        What is Confidential Information?
                      </h3>
                    </div>

                    <div className="content">
                      <p>
                        It is information that{" "}
                        <BlueUnderlineContainer
                          reduxStage={"3.1"}
                          text={"has not been made"}
                        />{" "}
                        public and is disclosed by one of the Party to the other
                        during our collaboration. This information is
                        confidential regardless of its{" "}
                        <BlueUnderlineContainer
                          reduxStage={"3.1"}
                          text={
                            "format, any mention as confidential, or the way it was"
                          }
                        />{" "}
                        shared between the parties.
                      </p>
                      <p>
                        <BlueUnderlineContainer
                          reduxStage={"3.1"}
                          text={"Confidential Information of a Party includes"}
                        />{" "}
                        information concerning such Party itself, its suppliers,
                        customers, assets, equipments, operations, software,
                        technology, prototypes, simulations, test results,
                        products.{" "}
                        <BlueUnderlineContainer
                          reduxStage={"3.1"}
                          text={"It also includes"}
                        />{" "}
                        the Party’s know-how, processes, methods, techniques,
                        concepts, specifications.{" "}
                        <BlueUnderlineContainer
                          reduxStage={"3.1"}
                          text={"Finally, Confidential Information includes"}
                        />{" "}
                        the Project itself, its existence and all discussions
                        and negotiations relating to it.
                      </p>
                    </div>
                  </div>
                </section>
              </SwiperSlide>

              <SwiperSlide key={4}>
                <section
                  className={
                    "part two" +
                    (props.stageData.contractActive ? " active" : " hide")
                  }
                >
                  <div className="rectangle-item">
                    <span className="mobile rectangle rectangle-1 pink"></span>
                  </div>
                  <div className="wrapper-container">
                    <div className="title-read-container">
                      <span className="number">2</span>
                      <h3 className="title">
                        What is <strong>NOT</strong> Confidential Information?
                      </h3>
                    </div>

                    <div className="content">
                      <div className="text-blockend-m">
                        <p>
                          Any information that is{" "}
                          <BlueUnderlineContainer
                            reduxStage={"3.2"}
                            text={"already public when shared"}
                          />
                          , unless it becomes public by breach of this Agreement
                          by one of the Parties.
                        </p>
                        <p>
                          Any information{" "}
                          <BlueUnderlineContainer
                            reduxStage={"3.2"}
                            text={
                              "already known by the Party at the time it is received"
                            }
                          />
                          , and for which it does not have an obligation of
                          confidentiality.
                        </p>
                        <p>
                          Any information that has been{" "}
                          <BlueUnderlineContainer
                            reduxStage={"3.2"}
                            text={
                              "disclosed on a non-confidential basis by a third party"
                            }
                          />{" "}
                          who did not have an obligation of confidentiality.
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
              </SwiperSlide>

              <SwiperSlide key={5}>
                <section
                  className={
                    "part three" +
                    (props.stageData.contractActive ? " active" : " hide")
                  }
                >
                  <div className="rectangle-item">
                    <span className="mobile rectangle rectangle-1 pink three-four three"></span>
                  </div>
                  <div className="rectangle-item">
                    <span className="mobile rectangle rectangle-2 pink three-four three"></span>
                  </div>

                  <div className="wrapper-container">
                    <div className="title-read-container">
                      <span className="number">3</span>
                      <h3 className="title wrap">
                        What do we have to do to protect Confidential
                        Information?
                      </h3>
                    </div>

                    <div className="content">
                      <div className="do-dont-container">
                        <div className="dont">
                          <div className="circle burgundy medium do-dont-circle">
                            <span>DONT's</span>
                          </div>

                          <div className="text">
                            <p>
                              The Party receiving the Confidential Information
                              will not :
                            </p>
                            <ul className="cross-list">
                              <li>Disclose them to third parties.</li>
                              <li>
                                Use it for any other purpose than the Project.
                              </li>
                              <li>
                                Make copies under any format of the Confidential
                                Information, except if it is necessary to
                                conduct the Project.
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="do text-blockend-m">
                          <div className="circle turquoise medium do-dont-circle">
                            <span>DO's</span>
                          </div>
                          <p>
                            The Parties must protect the Confidential
                            Information they receive in the{" "}
                            <BlueUnderlineContainer
                              reduxStage={"3.3"}
                              text={"same way they would protect their own"}
                            />{" "}
                            and in any case with a professional level of care.
                          </p>
                          <p>
                            The Party receiving the Confidential Information{" "}
                            <BlueUnderlineContainer
                              reduxStage={"3.3"}
                              text={"must only disclose it internally"}
                            />{" "}
                            to the individuals who need to know it to conduct
                            the Project. These individuals must be under
                            confidentiality obligations at least as protective
                            as those in this Agrement.{" "}
                          </p>
                          <p>
                            The Party receiving the Confidential Information
                            must{" "}
                            <BlueUnderlineContainer
                              reduxStage={"3.3"}
                              text={"make sure that these individuals"}
                            />{" "}
                            to whom it discloses Confidential Information{" "}
                            <BlueUnderlineContainer
                              reduxStage={"3.3"}
                              text={"will comply"}
                            />{" "}
                            with the terms of this Agreement.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </SwiperSlide>

              <SwiperSlide key={6}>
                <section
                  className={
                    "part four" +
                    (props.stageData.contractActive ? " active" : " hide")
                  }
                >
                  <div className="rectangle-item">
                    <span className="mobile rectangle rectangle-1 cream three-four four"></span>
                  </div>
                  <div className="rectangle-item">
                    <span className="mobile rectangle rectangle-2 cream three-four four"></span>
                  </div>
                  <div className="wrapper-container">
                    <div className="title-read-container">
                      <span className="number">4</span>
                      <h3 className="title wrap">
                        How will the parties communicate?
                      </h3>
                    </div>

                    <div className="content">
                      <p className="sub-title">
                        The Parties will communicate in writing by email to the
                        following people :
                      </p>

                      <div className="parties-info-wrapper">
                        <div className="parties-info">
                          <div className="party part-a">
                            <div className="circle dark-blue large">
                              <span>&</span>
                            </div>
                            <InputInfoContainer
                              input={"input"}
                              containerClass={"form-item main-contact"}
                              handleFormChange={handleFormChange}
                              value={values.contactNameA || ""}
                              error={
                                (reduxStage === "3.4" &&
                                  !values.contactNameA &&
                                  nextJustClicked) ||
                                (nextClickDtop && !values.contactNameA)
                              }
                              name={"contactNameA"}
                              type={"text"}
                              placeholder={"Main contact Name & Surname"}
                              text={
                                "Specify here the name and contact details of the project manager in charge. Be careful, it may not be the same person as the signatory of the document."
                              }
                            />

                            <div
                              className={
                                "form-item remove-margin email-a" +
                                (reduxStage === "3.4" &&
                                (!values.emailA || emailErr.emailA) &&
                                nextJustClicked
                                  ? " error"
                                  : "") +
                                (nextClickDtop &&
                                (!values.emailA || emailErr.emailA)
                                  ? " error"
                                  : "")
                              }
                            >
                              <input
                                onFocus={() => {
                                  props.formItemFocus();
                                }}
                                onBlur={() => {
                                  props.formItemBlur();
                                }}
                                onChange={handleFormChange}
                                value={values.emailA || ""}
                                name="emailA"
                                autoComplete="off"
                                type="email"
                                placeholder="Email address"
                              />
                            </div>
                          </div>

                          <div className="party part-b">
                            <InputInfoContainer
                              input={"input"}
                              containerClass={"form-item main-contact"}
                              handleFormChange={handleFormChange}
                              value={values.contactNameB || ""}
                              error={contactNameBError()}
                              name={"contactNameB"}
                              type={"text"}
                              placeholder={"Main contact Name & Surname"}
                              text={
                                "Specify here the name and contact details of the project manager in charge. Be careful, it may not be the same person as the signatory of the document."
                              }
                            />

                            <div
                              className={
                                "form-item remove-margin email-b" +
                                (reduxStage === "3.4" &&
                                (!values.emailB || emailErr.emailB) &&
                                nextJustClicked &&
                                partyBReturn
                                  ? " error"
                                  : "") +
                                (nextClickDtop &&
                                (!values.emailB || emailErr.emailB) &&
                                partyBReturn
                                  ? " error"
                                  : "")
                              }
                            >
                              <input
                                onFocus={() => {
                                  props.formItemFocus();
                                }}
                                onBlur={() => {
                                  props.formItemBlur();
                                }}
                                onChange={handleFormChange}
                                value={values.emailB || ""}
                                name="emailB"
                                autoComplete="off"
                                type="email"
                                placeholder="Email address"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </SwiperSlide>

              <SwiperSlide key={7}>
                <section
                  className={
                    "part-b five" +
                    (props.stageData.contractActive ? " active" : " hide")
                  }
                >
                  <div className="rectangle-item">
                    <span className="mobile rectangle rectangle-1 pink"></span>
                  </div>
                  <div className="wrapper-container">
                    <div className="title-read-container">
                      <span className="number">5</span>
                      <h3 className="title wrap">
                        Who owns the Confidential Information and is there an
                        obligation to share?
                      </h3>
                    </div>

                    <div className="content">
                      <div className="text-blockend-m">
                        <p>
                          All intellectual property rights contained in the
                          Confidential Information remain the sole property of
                          the Party who discloses it.
                        </p>
                        <p>
                          The sharing of Confidential Information between the
                          Parties{" "}
                          <BlueUnderlineContainer
                            reduxStage={"3.5"}
                            text={
                              "does not imply or give the receiving Party any ownership rights or license"
                            }
                          />{" "}
                          under trademark, patent, copyright, or any other
                          right.{" "}
                        </p>
                        <p>
                          Confidential Information is provided "
                          <BlueUnderlineContainer
                            reduxStage={"3.5"}
                            text={"as is"}
                          />
                          ". It means the Parties, when they share information,
                          do not guarantee or represent that the Confidential
                          Information is accurate or complete.
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
              </SwiperSlide>

              <SwiperSlide key={8}>
                <section
                  className={
                    "part six" +
                    (props.stageData.contractActive ? " active" : " hide")
                  }
                >
                  <div className="rectangle-item">
                    <span className="mobile rectangle rectangle-1 pink"></span>
                  </div>

                  <div className="wrapper-container">
                    <div className="title-read-container">
                      <span className="number">6</span>
                      <h3 className="title wrap">
                        What happens if one of the parties is requested to share
                        Confidential Information?
                      </h3>
                    </div>

                    <div className="content">
                      <p>
                        Parties can be required to disclose Confidential
                        Information by a lawfully issued court or governmental
                        order, or by the applicable law or regulation. In this
                        case, the required Party must:
                      </p>

                      <div className="list-container">
                        <ul className="tick-list">
                          <li>Inform the other party as soon as possible.</li>
                          <li>
                            Reasonably cooperate with the other Party's efforts
                            to oppose such disclosure or obtain a protective
                            order for such Confidential Information.
                          </li>
                        </ul>

                        <ul className="tick-list">
                          <li>
                            Only disclose the portion of the Confidential
                            Information required.
                          </li>
                          <li>
                            Make reasonable efforts to obtain that the disclosed
                            portion is treated confidentially.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </section>
              </SwiperSlide>

              <SwiperSlide key={9}>
                <section
                  className={
                    "part seven" +
                    (props.stageData.contractActive ? " active" : " hide")
                  }
                >
                  <div className="rectangle-item">
                    <span className="mobile rectangle rectangle-1 pink"></span>
                  </div>
                  <div className="rectangle-item">
                    <span className="desktop circle pink"></span>
                  </div>
                  <div className="wrapper-container">
                    <div className="title-read-container">
                      <span className="number">7</span>
                      <h3 className="title wrap">
                        How will a dispute between the Parties be solved?
                      </h3>
                    </div>

                    <div className="content">
                      <p>
                        If a dispute arises between the Parties during the
                        performance of this Confidentiality Agreement:
                      </p>
                      <div className="form-items-container-legal">
                        <div className="form-item horizontal-align legal margin-bottom">
                          <div
                            className={
                              "error-input-wrap select" +
                              (reduxStage === "3.7" &&
                              !values.legalRegulations &&
                              nextJustClicked
                                ? " error"
                                : "") +
                              (nextClickDtop && !values.legalRegulations
                                ? " error"
                                : "")
                            }
                          >
                            <select
                              onFocus={() => {
                                props.formItemFocus();
                              }}
                              onBlur={() => {
                                props.formItemBlur();
                              }}
                              onChange={handleFormChange}
                              value={values.legalRegulations}
                              name="legalRegulations"
                              defaultValue="placeholder"
                              disabled={!values.compNameA}
                            >
                              <option disabled value="placeholder">
                                e.g. French
                              </option>
                              {jurisdictions &&
                                jurisdictions.map((option) => (
                                  <option value={option.law}>
                                    {option.law}
                                  </option>
                                ))}
                            </select>
                          </div>

                          <label htmlFor="legalRegulations">
                            law will apply to this
                            <br />
                            Agreement.
                          </label>
                        </div>

                        <div className="circle dark-blue large desktop">
                          <span>&</span>
                        </div>

                        <div className="form-item horizontal-align legal margin-bottom">
                          <div
                            className={
                              "error-input-wrap" +
                              (reduxStage === "3.7" &&
                              !values.courts &&
                              nextJustClicked
                                ? " error"
                                : "") +
                              (nextClickDtop && !values.courts ? " error" : "")
                            }
                          >
                            <input
                              onFocus={() => {
                                props.formItemFocus();
                              }}
                              onBlur={() => {
                                props.formItemBlur();
                              }}
                              onChange={handleFormChange}
                              value={values.courts || ""}
                              name="courts"
                              disabled
                              type="text"
                              placeholder="e.g. Paris"
                            />
                          </div>

                          <label htmlFor="courts">
                            courts/tribunal who will
                            <br />
                            have jurisdiction.
                          </label>
                        </div>
                      </div>
                      <p>
                        If there is a breach or a risk of breach of this
                        Agreement, the disclosing Party may decide that money
                        damages are not an appropriate compensation and request
                        equitable remedies from a competent court. These
                        remedies will not be exclusive, and may be asked in
                        addition to all other rights and remedies the Disclosing
                        Party has.
                      </p>
                    </div>
                  </div>
                </section>
              </SwiperSlide>

              <SwiperSlide key={10}>
                <section
                  className={
                    "part eight" +
                    (props.stageData.contractActive ? " active" : " hide")
                  }
                >
                  <div className="rectangle-item">
                    <span className="rectangle mobile rectangle-1 pink"></span>
                  </div>
                  <div className="wrapper-container">
                    <div className="title-read-container">
                      <span className="number">8</span>
                      <h3 className="title wrap">
                        When must Confidential Information be returned or
                        destroyed?
                      </h3>
                    </div>

                    <div className="content">
                      <p>
                        Confidential Information must be returned or destroyed
                        by the Party who received it, if:
                      </p>
                      <div className="list-container">
                        <ul className="tick-list">
                          <li>The Agreement ends.</li>
                          <li>
                            The Parties do not pursue the Project further to
                            their discussions.
                          </li>
                        </ul>

                        <ul className="tick-list">
                          <li>The disclosing Party requests it at any time.</li>
                        </ul>
                      </div>

                      <div className="text-blockend-m">
                        <p>
                          The receiving Party must certify in writing that the
                          Confidential Information and all hard copies and
                          electronic records containing Confidential Information{" "}
                          <BlueUnderlineContainer
                            reduxStage={"3.8"}
                            text={"have been returned or destroyed"}
                          />
                          .
                        </p>
                        <p>
                          If the receiving Party is required by law to retain
                          copies of the Confidential Information from storage,
                          it must certify that it will{" "}
                          <BlueUnderlineContainer
                            reduxStage={"3.8"}
                            text={"nor use or copy them."}
                          />{" "}
                          for other purposes and{" "}
                          <BlueUnderlineContainer
                            reduxStage={"3.8"}
                            text={"treat them as confidential"}
                          />{" "}
                          The disclosing Party decides whether the Confidential
                          Information is returned or destroyed.
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
              </SwiperSlide>

              <SwiperSlide key={11}>
                <section
                  className={
                    "part nine" +
                    (props.stageData.contractActive ? " active" : " hide")
                  }
                >
                  <div className="wrapper-container">
                    <div className="title-read-container">
                      <span className="number">9</span>
                      <h3 className="title wrap">
                        Anything else We need to know about this Agreement?
                      </h3>
                    </div>

                    <div className="content">
                      <AccordionContainer
                        title={
                          "What if the discussions about the Project succeed?"
                        }
                        text={
                          "Congratulations! This Agreement only covers confidentiality and is not meant nor fit to replace SOPHiA GENETICS’s General Terms & Conditions or any commercial agreement. So the Parties will need to conclude a new contract, specific to the Project, to start working on it together. This Agreement will remain in force."
                        }
                        colour={"blue"}
                        swiper={swiper}
                      />

                      <AccordionContainer
                        title={
                          "What if the discussions about the Project fails?"
                        }
                        text={
                          "This Agreement only covers confidentiality and does not create any obligation for either Party to enter into any agreement or business relationship regarding the Project."
                        }
                        colour={"pink"}
                        swiper={swiper}
                      />
                      <AccordionContainer
                        title={"What if We want to change this Agreement?"}
                        text={
                          "The Parties can only do so in writing, by amending this Agreement."
                        }
                        colour={"blue"}
                        swiper={swiper}
                      />
                      <AccordionContainer
                        title={"What if part of this Agreement is invalid?"}
                        text={
                          "If any part of the Agreement is invalid, the invalid provision will not be considered as forming part of the Agreement, and all the other provisions will remain valid. The Parties will ensure to replace the invalid provision."
                        }
                        colour={"pink"}
                        swiper={swiper}
                      />
                      <AccordionContainer
                        title={
                          "Can the Parties transfer this Agreement to a third party?"
                        }
                        text={
                          "A Party can assign this Agreement only with the prior written consent of the other Party. The Agreement will bind the Parties’ respective successors."
                        }
                        colour={"blue"}
                        swiper={swiper}
                      />
                      <AccordionContainer
                        title={
                          "Can the Parties waive a right under this Agreement?"
                        }
                        text={
                          "Each Party can waive a right or remedy under this Agreement only explicitly and in writing. The waiver will be limited to the intended section of this Agreement."
                        }
                        textTwo={
                          "The delay or failure to exercise any right or remedy by a Party, even partially, does not constitute a waiver."
                        }
                        colour={"pink"}
                        swiper={swiper}
                      />
                      <AccordionContainer
                        title={"Is this all?"}
                        text={
                          "This Agreement constitutes the entire agreement between the Parties and supersedes all prior oral or written agreement having the same subject matter."
                        }
                        colour={"blue"}
                        swiper={swiper}
                      />
                    </div>
                  </div>
                </section>
              </SwiperSlide>

              <SwiperSlide key={12}>
                <section
                  ref={signatureStart}
                  className={
                    "sign" +
                    (props.stageData.contractActive ? " active" : " hide")
                  }
                >
                  <div className="rectangle-item">
                    <span className="mobile rectangle rectangle-1 pink"></span>
                  </div>
                  <div className="rectangle-item">
                    <span className="desktop rectangle rectangle-1 blue"></span>
                  </div>
                  <div className="wrapper-container">
                    <h2>Signatures</h2>
                    <p className="signature-subtitle">
                      Now We know how to protect our respective Confidential
                      Information, We can envisage collaborating! This Agreement
                      can be executed and delivered via electronic mail or other
                      secured electronic transmission methods, including any
                      electronic signature allowed under applicable law. The
                      Parties explicitly agree that such electronic signature
                      will have the same binding effect as a handwritten
                      signature. It will be valid and effective for all
                      purposes.
                    </p>

                    <div className="parties-info-wrapper">
                      <div className="parties-info signature">
                        <div className="container">
                          <div className="party part-a">
                            <div className="circle dark-blue large desktop">
                              <span>&</span>
                            </div>
                            <InputInfoContainer
                              input={"input"}
                              containerClass={
                                "form-item main-contact signature"
                              }
                              handleFormChange={handleFormChange}
                              value={values.contactNameASign || ""}
                              error={repNameASignError()}
                              name={"contactNameASign"}
                              type={"text"}
                              hideInfo
                              placeholder={"Name & Surname"}
                              text={
                                "Specify here the name and contact details of the person who is authorized to sign the agreement. It may not be the same person as the project manager !"
                              }
                              disabled
                            />
                            <div
                              className={
                                "form-item" +
                                (reduxStage === "4" &&
                                !values.positionASign &&
                                nextJustClicked
                                  ? " error"
                                  : "") +
                                (nextClickDtop && !values.positionASign
                                  ? " error"
                                  : "")
                              }
                            >
                              <input
                                onFocus={() => {
                                  props.formItemFocus();
                                }}
                                onBlur={() => {
                                  props.formItemBlur();
                                }}
                                onChange={handleFormChange}
                                value={values.positionASign || ""}
                                name="positionASign"
                                type="text"
                                placeholder="Position"
                                disabled={true}
                              />
                            </div>
                            <div
                              className={
                                "form-item" +
                                (reduxStage === "4" &&
                                (!values.emailASign ||
                                  emailErrorSignature.emailASign) &&
                                nextJustClicked
                                  ? " error"
                                  : "") +
                                (nextClickDtop &&
                                (!values.emailASign || emailErr.emailASign)
                                  ? " error"
                                  : "")
                              }
                            >
                              <input
                                onFocus={() => {
                                  props.formItemFocus();
                                }}
                                onBlur={() => {
                                  props.formItemBlur();
                                }}
                                className="email-a"
                                onChange={handleFormChange}
                                value={values.emailASign || ""}
                                name="emailASign"
                                autoComplete="off"
                                type="email"
                                placeholder="Email"
                                disabled={true}
                              />
                            </div>

                            <div className="signature-container">
                              <div className="form-item horizontal-align">
                                <span>Duly authorized</span>
                              </div>
                            </div>
                          </div>

                          <div className="party part-a">
                            <div className="circle dark-blue large mobile">
                              <span>&</span>
                            </div>
                            <InputInfoContainer
                              input={"select"}
                              containerClass={
                                "form-item main-contact signature"
                              }
                              handleFormChange={handleFormChange}
                              value={values.contactNameCSign}
                              error={repNameCSignError()}
                              name={"contactNameCSign"}
                              options={repNameCOptions}
                              disabled={partyBReturn}
                              hideInfo
                              placeholder={"Name & Surname"}
                              text={
                                "Specify here the name and contact details of the person who is authorized to sign the agreement. It may not be the same person as the project manager !"
                              }
                            />
                            <div
                              className={
                                "form-item" +
                                (reduxStage === "4" &&
                                !values.positionCSign &&
                                nextJustClicked
                                  ? " error"
                                  : "") +
                                (nextClickDtop && !values.positionCSign
                                  ? " error"
                                  : "")
                              }
                            >
                              <input
                                onFocus={() => {
                                  props.formItemFocus();
                                }}
                                onBlur={() => {
                                  props.formItemBlur();
                                }}
                                onChange={handleFormChange}
                                value={values.positionCSign || ""}
                                name="positionCSign"
                                type="text"
                                placeholder="Position"
                                disabled
                              />
                            </div>
                            <div
                              className={
                                "form-item" +
                                (reduxStage === "4" &&
                                (!values.emailCSign ||
                                  emailErrorSignature.emailCSign) &&
                                nextJustClicked
                                  ? " error"
                                  : "") +
                                (nextClickDtop &&
                                (!values.emailCSign || emailErr.emailCSign)
                                  ? " error"
                                  : "")
                              }
                            >
                              <input
                                onFocus={() => {
                                  props.formItemFocus();
                                }}
                                onBlur={() => {
                                  props.formItemBlur();
                                }}
                                className="email-c"
                                onChange={handleFormChange}
                                value={values.emailCSign || ""}
                                name="emailCSign"
                                autoComplete="off"
                                type="email"
                                placeholder="Email"
                                disabled
                              />
                            </div>

                            <div className="signature-container">
                              <div className="form-item horizontal-align">
                                <span>Duly authorized</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="party part-b">
                          <InputInfoContainer
                            input={"input"}
                            containerClass={"form-item main-contact signature"}
                            handleFormChange={handleFormChange}
                            value={values.contactNameBSign || ""}
                            error={contactNameBSignError()}
                            name={"contactNameBSign"}
                            type={"text"}
                            placeholder={"Name & Surname"}
                            text={
                              "Specify here the name and contact details of the person who is authorized to sign the Agre-ement. It may not be the same person as the project manager but his manager for instance !"
                            }
                          />
                          <div
                            className={
                              "form-item" +
                              (reduxStage === "4" &&
                              !values.positionBSign &&
                              nextJustClicked &&
                              partyBReturn
                                ? " error"
                                : "") +
                              (nextClickDtop &&
                              !values.positionBSign &&
                              partyBReturn
                                ? " error"
                                : "")
                            }
                          >
                            <input
                              onFocus={() => {
                                props.formItemFocus();
                              }}
                              onBlur={() => {
                                props.formItemBlur();
                              }}
                              onChange={handleFormChange}
                              value={values.positionBSign || ""}
                              name="positionBSign"
                              type="text"
                              placeholder="Position"
                            />
                          </div>
                          <div
                            className={
                              "form-item" +
                              (reduxStage === "4" &&
                              (!values.emailBSign ||
                                emailErrorSignature.emailBSign) &&
                              nextJustClicked
                                ? " error"
                                : "") +
                              (nextClickDtop &&
                              (!values.emailBSign || emailErr.emailBSign)
                                ? " error"
                                : "")
                            }
                          >
                            <input
                              onFocus={() => {
                                props.formItemFocus();
                              }}
                              onBlur={() => {
                                props.formItemBlur();
                              }}
                              className="email-b"
                              onChange={handleFormChange}
                              value={values.emailBSign || ""}
                              name="emailBSign"
                              autoComplete="off"
                              type="email"
                              placeholder="Email"
                            />
                          </div>

                          <div className="signature-container">
                            <div className="form-item horizontal-align">
                              <span>Duly authorized</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="confirm-send-container desktop">
                      <p>Once you have filled all your information, you can:</p>
                      <button
                        onClick={() => {
                          handleSubmitDesktop();
                        }}
                        className="rounded-edge-button"
                      >
                        <span>Confirm and send</span>
                      </button>
                    </div>

                    <div className="confirm-send-container mobile">
                      <button
                        onClick={(e) => setGoToAgree({ goTo: true, event: e })}
                        className="rounded-edge-button"
                      >
                        <span>Confirm & Send</span>
                      </button>
                    </div>
                  </div>
                </section>
              </SwiperSlide>

              <SwiperSlide key={13}>
                <section
                  ref={agreeSect}
                  className={
                    "agreement-section" +
                    (props.stageData.agreementActive ? " active" : " hide")
                  }
                >
                  <span className="mobile rectangle rectangle-1 pink"></span>

                  <div className="wrapper-container">
                    <div className="content-container">
                      <div className="quote-header">
                        <h2>Don’t forget to apply this Agreement!</h2>
                      </div>

                      <p>Here is a reminder of key points:</p>
                      <div className="list-container">
                        <ul className="tick-list">
                          <li>
                            <BlueUnderlineContainer
                              reduxStage={"5"}
                              agreement={true}
                              text={"Sign the Agreement"}
                            />{" "}
                            before sharing confidential information
                          </li>
                          <li>
                            Protect Confidential Information of the other Party{" "}
                            <BlueUnderlineContainer
                              reduxStage={"5"}
                              agreement={true}
                              text={"as if it were your own"}
                            />
                          </li>
                          <li>
                            Only use the Confidential Information for the
                            Project, and{" "}
                            <BlueUnderlineContainer
                              reduxStage={"5"}
                              agreement={true}
                              text={"not any other purpose"}
                            />
                          </li>
                          <li>
                            Only share it{" "}
                            <BlueUnderlineContainer
                              reduxStage={"5"}
                              agreement={true}
                              text={"internally"}
                            />{" "}
                            and with persons who need to know it
                          </li>
                        </ul>

                        <ul className="tick-list">
                          <li>
                            Ensure all persons having access to it protect it as{" "}
                            <BlueUnderlineContainer
                              reduxStage={"5"}
                              agreement={true}
                              text={"if it were their own"}
                            />
                          </li>
                          <li>
                            This Agreement does not give any Party{" "}
                            <BlueUnderlineContainer
                              reduxStage={"5"}
                              agreement={true}
                              text={"any right"}
                            />{" "}
                            on the Confidential Information of the other Party
                          </li>
                          <li>
                            If discussions on the Project succeed, the Parties
                            will need to{" "}
                            <BlueUnderlineContainer
                              reduxStage={"5"}
                              agreement={true}
                              text={"sign a specific agreement"}
                            />
                          </li>
                          <li>
                            And of course, all clauses are important: read the
                            NDA entirely!
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="footer-agreement-container">
                      <a
                        href="/"
                        onClick={() => {
                          return (
                            props.startActive(),
                            props.switchToStart(),
                            scrollToTop()
                          );
                        }}
                        className="home-page"
                      >
                        Go back to home page
                      </a>

                      <div className={"popup" + (popupActive ? " active" : "")}>
                        <span>
                          The agreement was sent for review & completion to
                          SOPHiA GENETICS, Congratulations!  <br /> <br />
                          Make sure to check your spams – not to miss SOPHiA
                          GENETICS’ request for you to sign the completed NDA.
                        </span>
                      </div>
                    </div>
                  </div>
                </section>
              </SwiperSlide>
            </Swiper>

            <SwiperNavContainer
              swiper={swiper}
              slideToId={slideToId}
              screenHeight={screenHeight}
              postPutForm={() => postPutForm()}
              fillInfoStart={fillInfoStart}
              readCarefulStart={readCarefulStart}
              signatureStart={signatureStart}
              goToAgree={goToAgree}
            />
          </div>
        </div>
      </main>
    </>
  );
}

function mapStateToProps(state) {
  return {
    stageData: state.stage,
    formData: state.form,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    switchToStart: () => dispatch(switchToStart()),
    switchToContract: () => dispatch(switchToContract()),
    switchToAgreement: () => dispatch(switchToAgreement()),
    activeStart: () => dispatch(activeStart()),
    activeFillInfo: () => dispatch(activeFillInfo()),
    activeAgreement: () => dispatch(activeAgreement()),
    contractSectionForm: () => dispatch(contractSectionForm()),
    formSent: () => dispatch(formSent()),
    partyBReturn: () => dispatch(partyBReturn()),
    formItemFocus: () => dispatch(formItemFocus()),
    formItemBlur: () => dispatch(formItemBlur()),
    userCanGoNext: () => dispatch(userCanGoNext()),
    userCannotGoNext: () => dispatch(userCannotGoNext()),
    dataMissing: () => dispatch(dataMissing()),
    dataNotMissing: () => dispatch(dataNotMissing()),
    inputsOnPage: () => dispatch(inputsOnPage()),
    noInputsOnPage: () => dispatch(noInputsOnPage),
    userClickedNextInputsOnPage: () => dispatch(userClickedNextInputsOnPage()),
    setResizedToMobile: () => dispatch(setResizedToMobile()),
    setResizedToDesktop: () => dispatch(setResizedToDesktop()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
