import React from 'react';
import ReactDOM from 'react-dom';

const SpinningLoader = props => ReactDOM.createPortal(
    <>
        <div className={"loading-container" + (props.active ? '' : ' hide')}>
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
    </>, 
        document.getElementById('loader-root')
);

export default SpinningLoader