import React, { useState, useRef, useEffect } from 'react';
import { isMobile } from "react-device-detect";
import { connect } from 'react-redux';

// underline text
function BlueUnderlineContainer(props) {
    let blueRef = useRef()
    let [active, setActive] = useState(false)

    let contractActive = props.stageData.contractActive
    let stageChange = props.stageData.stage


    // for blue underline effect
    useEffect(() => {
        // desktop
        const handleScroll = event => {
            if (blueRef.current.getBoundingClientRect().top < (window.innerHeight * 0.5) && blueRef.current.getBoundingClientRect().left !== 0 && !active) 
                setActive(true)
        }
        // mobile
        const  handleTouch = event => {
            if (props.reduxStage === props.stageData.stage && blueRef.current.getBoundingClientRect().top < window.innerHeight * 1 && !active)
                setActive(true)
        }
        
        // for desktop and already on screen
        // if (blueRef.current.getBoundingClientRect().top < (window.innerHeight * 0.5) && blueRef.current.getBoundingClientRect().left !== 0 && !active) 
        if (props.displayAlways) 
            setActive(true)

        // for mobile and already on screen
        if (props.reduxStage === props.stageData.stage && blueRef.current.getBoundingClientRect().top < (window.innerHeight * 0.9) && !active)
            setActive(true)

        // for  agreement section on desktop
        if(!isMobile && props.stageData.agreementActive && props.agreement)
            setActive(true)

            window.addEventListener('touchend', handleTouch)

        if (contractActive)
            window.addEventListener('scroll', handleScroll)

        return () => window.removeEventListener('scroll', handleScroll);

    }, [blueRef, active, contractActive, stageChange, props.agreement, props.reduxStage, props.stageData.stage, props.stageData.agreementActive])


    return (
        <span ref={blueRef} className={"blue-underline" + (active ? ' active' : '') + (props.bold ? ' bold' : '')}>
           {props.text}
        </span>
    )
}

function mapStateToProps(state) {
    return { stageData: state.stage };
}

export default connect(mapStateToProps)(BlueUnderlineContainer)